import React from "react";

import Button from "../../../components/Button";
import HeaderMobile from "../../../components/HeaderMobile";

import {
  Container,
  ContainerDescription,
  ContainerImages,
  ContainerCovid,
  ContainerText,
  Title1,
  Title2,
  TitleCovid,
  App,
  LitleDivisor,
  ContainerGrey,
  DivIcons,
  IconDescription,
  Icones,
  ContainerWhatsapp,
} from "./styles";

import SellMore from "../../../components/SellMore";
import Footer from "../../../components/Footer";
import Banner from "../../../components/Tela6/Rodape";
import Titulo8 from "../../../components/Tela8/Titulo8";
import FormGeneral from "../../../components/FormGeneral";
import FastDigitalMobile from "../../../components/FastDigitalMobile";
import WhoUseMobile from "../../../components/WhoUseMobile";

import Element01 from "../../../assets/images/elemento01.png";
import Element02 from "../../../assets/images/elemento02.png";
import Iphone from "../../../assets/images/iphone.png";
import Element_SVG01 from "../../../assets/element_bg01.svg";
import Element04 from "../../../assets/images3/elemento4.png";
import Element05 from "../../../assets/images3/elemento5.png";
import iphone2 from "../../../assets/delivery_wpp.png";
import IconWpp from "../../../assets/icon_wpp.svg";
import fotos from "../../../assets/images3/fotos.svg";
import toogles from "../../../assets/images3/toogles.svg";
import money from "../../../assets/images3/money.svg";
import news from "../../../assets/images3/news.svg";
import checklist from "../../../assets/images3/checklist.svg";
import ElementBGWpp from "../../../assets/element_bg01_wpp.svg";
import PlansMobile from "../../../components/PlansMobile";
import FormMobile from "../../../components/FormMobile";
import TranslatorFunction from '../../../components/I18n/Translator';

function Mobile() {

  const theMostSolution = TranslatorFunction({ path: 'theMostSolution.message' });
  const smartToPresent = TranslatorFunction({ path: 'smartToPresent.message' });
  const yourMenu = TranslatorFunction({ path: 'yourMenu.message' });
  const myTripMenuIsADigitalMenuWithPhotos = TranslatorFunction({ path: 'myTripMenuIsADigitalMenuWithPhotos.message' });
  const accessedByQRCodeDirectlyFromTheCustomersSmartphone = TranslatorFunction({ path: 'accessedByQRCodeDirectlyFromTheCustomersSmartphone.message' });
  const simpleEasyAndFast = TranslatorFunction({ path: 'simpleEasyAndFast.message' });
  const start = TranslatorFunction({ path: 'start.message' });
  const thePerfectSolutionForYourSecurityProtocols = TranslatorFunction({ path: 'thePerfectSolutionForYourSecurityProtocols.message' });
  const withMyTripYouCreateASafeEnvironmentAndAvoidTransmissionOfTheNewCoronavirus = TranslatorFunction({ path: 'withMyTripYouCreateASafeEnvironmentAndAvoidTransmissionOfTheNewCoronavirus.message' });
  const receiveYourOrders = TranslatorFunction({ path: 'receiveYourOrders.message' });
  const by = TranslatorFunction({ path: 'by.message' });
  const automateTheService = TranslatorFunction({ path: 'automateTheService.message' });
  const ofYourDelivery = TranslatorFunction({ path: 'ofYourDelivery.message' });
  const yourCustomersAccessA = TranslatorFunction({ path: 'yourCustomersAccessA.message' });
  const linkWithYourDigitalMenu = TranslatorFunction({ path: 'linkWithYourDigitalMenu.message' });
  const chooseTheProductsAndYou = TranslatorFunction({ path: 'chooseTheProductsAndYou.message' });
  const receiveTheOrderCirectlyOn = TranslatorFunction({ path: 'receiveTheOrderCirectlyOn.message' });
  const speed = TranslatorFunction({ path: 'speed.message' });
  const agility = TranslatorFunction({ path: 'agility.message' });
  const orders = TranslatorFunction({ path: 'orders.message' });
  const profits = TranslatorFunction({ path: 'profits.message' });
  const testServiceLink = TranslatorFunction({ path: 'testServiceLink.message' });
  const noAppNeeded = TranslatorFunction({ path: 'noAppNeeded.message' });
  const sellMoreAndSaveOnPrintingCosts = TranslatorFunction({ path: 'sellMoreAndSaveOnPrintingCosts.message' });
  const aDigitalSolution = TranslatorFunction({ path: 'aDigitalSolution.message' });
  const forYourCompany = TranslatorFunction({ path: 'forYourCompany.message' });
  const getAccessToARangeOfFeatures = TranslatorFunction({ path: 'getAccessToARangeOfFeatures.message' });
  const thatWillFacilitateTheManagementOfYourBusiness = TranslatorFunction({ path: 'thatWillFacilitateTheManagementOfYourBusiness.message' });
  const fullMenuWithPhotos = TranslatorFunction({ path: 'fullMenuWithPhotos.message' });
  const activateAndDeactivateItemsWithEase = TranslatorFunction({ path: 'activateAndDeactivateItemsWithEase.message' });
  const realTimePriceUpdate = TranslatorFunction({ path: 'realTimePriceUpdate.message' });
  const testNewProductsQuickly = TranslatorFunction({ path: 'testNewProductsQuickly.message' });
  const selectionFiltersByProductType = TranslatorFunction({ path: 'selectionFiltersByProductType.message' });


  return (
    <>
      <Container>
        <HeaderMobile />
        <ContainerDescription>
          <h1>
            {theMostSolution} <br />
            {smartToPresent} <br /> {yourMenu}
          </h1>
          <h2>
            {myTripMenuIsADigitalMenuWithPhotos}, <br /> {accessedByQRCodeDirectlyFromTheCustomersSmartphone}. <br />
            {simpleEasyAndFast}.
          </h2>
          <Button>{start}</Button>
        </ContainerDescription>
      </Container>
      <ContainerImages>
        <div className="mobile">
          <img src={Element01} className="right" alt="Elemento" />
          <img src={Element02} className="left" alt="Elemento" />
          <img src={Iphone} alt="" className="iphonepic" />
        </div>
      </ContainerImages>
      <Container>
        <ContainerCovid>
          <ContainerText>
            <TitleCovid>COVID-19</TitleCovid>
            <Title1>
              {thePerfectSolutionForYourSecurityProtocols}
            </Title1>
            <Title2>
              {withMyTripYouCreateASafeEnvironmentAndAvoidTransmissionOfTheNewCoronavirus}
            </Title2>
          </ContainerText>
        </ContainerCovid>
      </Container>
      <ContainerImages>
        <ContainerWhatsapp>
          <img src={ElementBGWpp} alt="Elemento visual" className="img-wpp" />
          <div className="titles-wpp">
            <h1>
              {receiveYourOrders} <br />
              {by} WhatsApp
            </h1>
            <LitleDivisor />
            <h3>
              {automateTheService} <br />
              {ofYourDelivery}
            </h3>
          </div>
          <div className="texts-wpp">
            <div className="texts-wpp-div">
              <h1>
                {yourCustomersAccessA} <br />
                {linkWithYourDigitalMenu} <br />
                {chooseTheProductsAndYou}
                <br /> {receiveTheOrderCirectlyOn} <br />
                WhatsApp.
              </h1>
              <div className="border-text">
                + {speed} + {agility} + {orders} + {profits}
              </div>
              <a
                href={`${process.env.REACT_APP_DEMOS}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button>
                  <h3>{testServiceLink}</h3>
                </Button>
              </a>
            </div>
            <div className="texts-img-div">
              <img src={iphone2} alt="Iphone Cardápio" />
            </div>
          </div>
        </ContainerWhatsapp>
      </ContainerImages>

      <ContainerImages>
        <img src={Element_SVG01} className="noapp-image-left" alt="" />
        {/* <img src={Element_SVG02} className="noapp-image-right" /> */}
        <App>{noAppNeeded}</App>
        <LitleDivisor />
        <h1 className="title">
          {sellMoreAndSaveOnPrintingCosts}
        </h1>
      </ContainerImages>
      <SellMore />
      <ContainerGrey>
        <div className="solution">
          <img src={Element04} alt="elemento visual" className="left" />
          <img src={Element05} alt="elemento visual" className="right" />
          <div className="titles">
            <h1>
              {aDigitalSolution}
              <br /> {forYourCompany}
            </h1>
            <LitleDivisor />
            <h2>
              {getAccessToARangeOfFeatures} <br />
              {thatWillFacilitateTheManagementOfYourBusiness}
            </h2>
          </div>

          <DivIcons>
            <img src={iphone2} className="iphoneIcon" alt="" />
            <Icones>
              <IconDescription>
                <img src={IconWpp} alt="" />
                <div>
                  <h4>{receiveYourOrders} {by} WhatsApp</h4>
                </div>
              </IconDescription>
              <IconDescription>
                <img src={fotos} alt="" />
                <div>
                  <h4>{fullMenuWithPhotos}</h4>
                </div>
              </IconDescription>
              <IconDescription>
                <img src={toogles} alt="" />
                <div>
                  <h4>{activateAndDeactivateItemsWithEase}</h4>
                </div>
              </IconDescription>
              <IconDescription>
                <img src={money} alt="" />
                <div>
                  <h4>{realTimePriceUpdate}</h4>
                </div>
              </IconDescription>
              <IconDescription>
                <img src={news} alt="" />
                <div>
                  <h4>{testNewProductsQuickly}</h4>
                </div>
              </IconDescription>
              <IconDescription>
                <img src={checklist} alt="" />
                <div>
                  <h4> {selectionFiltersByProductType}</h4>
                </div>
              </IconDescription>
            </Icones>
          </DivIcons>
        </div>
      </ContainerGrey>
      <FastDigitalMobile LitleDivisor={LitleDivisor} />
      <WhoUseMobile LitleDivisor={LitleDivisor} />
      <Banner />
      <PlansMobile LitleDivisor={LitleDivisor} />
      <FormMobile LitleDivisor={LitleDivisor} />
      <Titulo8 />
      <FormGeneral />
      <Footer />
    </>
  );
}

export default Mobile;
