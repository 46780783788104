import React, { useState } from "react";
import { isValidCNPJ, isValidPhone } from "@brazilian-utils/brazilian-utils";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import ReactPixel from "react-facebook-pixel";

import elemento12 from "../../assets/elemento18_web.png";
import iphone05 from "../../assets/iphone5_web.png";
import { useForm } from "react-hook-form";
import api from "../../services/api";
import InputMasked from "../InputMasked";

import {
  Sobre,
  Asterisco,
  Preenchimento,
  Container,
  FormContainer,
  SobreEstabelecimento,
  ContainerMobile,
  ContainerWeb,
  Texts,
  ContainerForm,
} from "./style";

import TranslatorFunction from "../I18n/Translator";

const FormGeneral = (props) => {
  const registrationSuccessful = TranslatorFunction({
    path: "registrationSuccessful.message",
  });
  const checkYourEmailInboxAndYourSpam = TranslatorFunction({
    path: "checkYourEmailInboxAndYourSpam.message",
  });
  const pleaseEnterAValidSubdomain = TranslatorFunction({
    path: "pleaseEnterAValidSubdomain.message",
  });
  const checkAllFields = TranslatorFunction({ path: "checkAllFields.message" });
  const existingEmailTryAgain = TranslatorFunction({
    path: "existingEmailTryAgain.message",
  });
  const invalidEmailTryAgain = TranslatorFunction({
    path: "invalidEmailTryAgain.message",
  });
  const invalidLastNameTryAgain = TranslatorFunction({
    path: "invalidLastNameTryAgain.message",
  });
  const invalidCNPJTryAgain = TranslatorFunction({
    path: "invalidCNPJTryAgain.message",
  });
  const CNPJAlreadyRegisteredTryAgain = TranslatorFunction({
    path: "CNPJAlreadyRegisteredTryAgain.message",
  });
  const invalidNumberTryAgain = TranslatorFunction({
    path: "invalidNumberTryAgain.message",
  });
  const subdomainAlreadyExistsTryAgain = TranslatorFunction({
    path: "subdomainAlreadyExistsTryAgain.message",
  });
  const invalidSubdomainTryAgain = TranslatorFunction({
    path: "invalidSubdomainTryAgain.message",
  });
  const somethingUnexpectedHappenedTryAgainLater = TranslatorFunction({
    path: "somethingUnexpectedHappenedTryAgainLater.message",
  });
  const freeTtrialFor30days = TranslatorFunction({
    path: "freeTtrialFor30days.message",
  });
  const makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow =
    TranslatorFunction({
      path: "makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow.message",
    });
  const aboutYou = TranslatorFunction({ path: "aboutYou.message" });
  const mandatoryFilling = TranslatorFunction({
    path: "mandatoryFilling.message",
  });
  const firstName = TranslatorFunction({ path: "firstName.message" });
  const enterYourName = TranslatorFunction({ path: "enterYourName.message" });
  const secondName = TranslatorFunction({ path: "secondName.message" });
  const enterYourSecondName = TranslatorFunction({
    path: "enterYourSecondName.message",
  });
  const contactEmail = TranslatorFunction({ path: "contactEmail.message" });
  const enterYourEmail = TranslatorFunction({ path: "enterYourEmail.message" });
  const mobileWhatsapp = TranslatorFunction({ path: "mobileWhatsapp.message" });
  const enterYourContactNumber = TranslatorFunction({
    path: "enterYourContactNumber.message",
  });
  const pleaseEnterYourValidNumber = TranslatorFunction({
    path: "pleaseEnterYourValidNumber.message",
  });
  const aboutTheEstablishments = TranslatorFunction({
    path: "aboutTheEstablishments.message",
  });
  const nameOfTheEstablishment = TranslatorFunction({
    path: "nameOfTheEstablishment.message",
  });
  const enterTheNameOfYourEstablishment = TranslatorFunction({
    path: "enterTheNameOfYourEstablishment.message",
  });
  const enterYourCNPJ = TranslatorFunction({ path: "enterYourCNPJ.message" });
  const enterAValidCNPJ = TranslatorFunction({
    path: "enterAValidCNPJ.message",
  });
  const chooseAURLForYourMenu = TranslatorFunction({
    path: "chooseAURLForYourMenu.message",
  });
  const emailForLogosInTheManager = TranslatorFunction({
    path: "emailForLogosInTheManager.message",
  });
  const enterAValidEmail = TranslatorFunction({
    path: "enterAValidEmail.message",
  });
  const start = TranslatorFunction({ path: "start.message" });
  const chooseASubdomainForYourMenu = TranslatorFunction({
    path: "chooseASubdomainForYourMenu.message",
  });

  const { register, handleSubmit } = useForm();
  const [dataFormated, setDataFormated] = useState({
    cnpj: "",
    phone: "",
  });
  const [url, setUrl] = useState("");
  const [urlError, setUrlError] = useState("");
  const [prefixInput, setPrefixInput] = useState("");
  const [send, setSend] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState("");
  const [data, setData] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    razaosocial: "",
    cnpj: "",
    subdomain: "",
    gerenciador: "",
  });

  const [errorss, setErrors] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    razaosocial: "",
    cnpj: "",
    subdomain: "",
    gerenciador: "",
  });

  async function onSubmit() {
    try {
      if (send) {
        setErrorSubmit("");
        if (url !== "") {
          await api.post("users", {
            userFirstName: data.name,
            userLastName: data.surname,
            userEmail: data.email,
            userPhone: data.phone.replace(/[^0-9]+/g, ""),
            companyEmail: data.gerenciador,
            companyName: data.razaosocial,
            subDomain: url,
            CNPJ: data.cnpj,
          });

          setData({
            name: "",
            surname: "",
            email: "",
            phone: "",
            razaosocial: "",
            cnpj: "",
            subdomain: "",
            gerenciador: "",
          });

          setPrefixInput("");
          setUrl("");
          setUrlError("");

          Swal.fire(
            registrationSuccessful,
            checkYourEmailInboxAndYourSpam,
            "success"
          );
          ReactPixel.fbq("track", "Lead");
        } else {
          setUrlError(pleaseEnterAValidSubdomain);
        }
      } else {
        setErrorSubmit(checkAllFields);
      }
    } catch (error) {
      if (
        error.response.data?.message ===
        "CreateUserUseCase: company email already exists."
      ) {
        setErrors({
          ...errorss,
          gerenciador: existingEmailTryAgain,
        });
      } else if (
        error.response.data?.message ===
        "CreateUserUseCase: company email is not valid."
      ) {
        setErrors({
          ...errorss,
          gerenciador: invalidEmailTryAgain,
        });
      } else if (
        error.response.data?.message ===
        "CreateUserUseCase: user last name is no valid."
      ) {
        setErrors({
          ...errorss,
          surname: invalidLastNameTryAgain,
        });
      } else if (
        error.response.data?.message ===
        "CreateUserUseCase: user email is not valid."
      ) {
        setErrors({ ...errorss, email: invalidEmailTryAgain });
      } else if (
        error.response.data?.message ===
        "CreateUserUseCase: user phone is not valid."
      ) {
        setErrors({ ...errorss, phone: invalidNumberTryAgain });
      } else if (
        error.response.data?.message === "CreateUserUseCase: CNPJ is not valid."
      ) {
        setErrors({ ...errorss, cnpj: invalidCNPJTryAgain });
      } else if (
        error.response.data?.message ===
        "CreateUserUseCase: CNPJ already saved on PaymentService." ||
        error.response.data?.message ===
        "CreateUserUseCase: CNPJ already saved on PaymentService"
      ) {
        setErrors({ ...errorss, cnpj: CNPJAlreadyRegisteredTryAgain });
      } else if (
        error.response.data?.message ===
        "CreateUserUseCase: sub domain already exists."
      ) {
        setErrors({
          ...errorss,
          subdomain: subdomainAlreadyExistsTryAgain,
        });
        setErrorSubmit(subdomainAlreadyExistsTryAgain)
      } else if (
        error.response.data?.message ===
        "CreateUserUseCase: subDomain is not valid."
      ) {
        setErrorSubmit(invalidSubdomainTryAgain);
      }      
      else {
        setErrorSubmit(somethingUnexpectedHappenedTryAgainLater);
      }
    }
  }

  const handleVerify = () => {
    if (
      data.name.length > 0 &&
      data.surname.length > 0 &&
      data.email.length > 0 &&
      data.phone.length > 0 &&
      data.razaosocial.length > 0 &&
      data.cnpj.length > 0 &&
      data.gerenciador.length > 0 &&
      url
    ) {
      setSend(true);
    } else {
      setSend(false);
    }
  };
  return (
    <>
      <ContainerWeb id="register">
        <img src={elemento12} className="el01" alt="" />
        <div className="texts">
          <Texts>
            <h1>{freeTtrialFor30days}</h1>
            <h2>
              {
                makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow
              }
            </h2>
          </Texts>
          <ContainerForm>
            <div className="container">
              <Sobre>{aboutYou}</Sobre>
              <div className="required">
                <Asterisco>*</Asterisco>
                <Preenchimento>{mandatoryFilling}</Preenchimento>
              </div>
            </div>
            <FormContainer
              onBlur={handleVerify}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-group">
                <div>
                  <input
                    type="text"
                    name="name"
                    placeholder={firstName}
                    className="nome required"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    onBlur={(e) => {
                      if (e.target.value.length === 0) {
                        setErrors({ ...errorss, name: enterYourName });
                        setSend(false);
                      } else {
                        setErrors({
                          ...errorss,
                          name: "",
                        });
                      }
                    }}
                    ref={register}
                  ></input>
                  <br />
                  {errorss.name.length > 0 && (
                    <h5 className="errornome">{errorss.name}</h5>
                  )}
                </div>
                <div>
                  <input
                    type="text"
                    name="surname"
                    placeholder={secondName}
                    className="sobrenome"
                    value={data.surname}
                    onChange={(e) =>
                      setData({ ...data, surname: e.target.value })
                    }
                    onBlur={(e) => {
                      if (e.target.value.length === 0) {
                        setErrors({
                          ...errorss,
                          surname: enterYourSecondName,
                        });
                        setSend(false);
                      } else {
                        setErrors({
                          ...errorss,
                          surname: "",
                        });
                      }
                    }}
                    ref={register}
                  />
                  <br />
                  {errorss.surname.length > 0 && (
                    <h5 className="errornome">{errorss.surname}</h5>
                  )}
                </div>
              </div>

              <div className="form-one top">
                <input
                  name="email"
                  placeholder={contactEmail}
                  className="email"
                  type="email"
                  value={data.email}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                  onBlur={(e) => {
                    if (e.target.value.length === 0) {
                      setErrors({
                        ...errorss,
                        email: enterYourEmail,
                      });
                      setSend(false);
                    } else {
                      setErrors({
                        ...errorss,
                        email: "",
                      });
                    }
                  }}
                  ref={register}
                />
                <br />
                {errorss.email.length > 0 && (
                  <h5 className="errornome">{errorss.email}</h5>
                )}
              </div>

              <div className="form-one">
                <InputMasked
                  name="whatsapp"
                  placeholder={mobileWhatsapp}
                  className="whatsapp"
                  mask="phone"
                  value={data.phone}
                  onChange={(e) => setData({ ...data, phone: e.target.value })}
                  onBlur={(e) => {
                    setDataFormated({ ...dataFormated, phone: e.target.value });
                    if (e.target.value.length === 0) {
                      setErrors({
                        ...errorss,
                        phone: enterYourContactNumber,
                      });
                      setSend(false);
                    } else if (
                      (e.target.value.length === 16 ||
                        e.target.value.length === 15) &&
                      !isValidPhone(e.target.value.replace(/[^0-9]+/g, ""))
                    ) {
                      setErrors({
                        ...errorss,
                        phone: pleaseEnterYourValidNumber,
                      });
                      setSend(false);
                    } else {
                      setErrors({
                        ...errorss,
                        phone: "",
                      });
                    }
                  }}
                  ref={register}
                />
                <br />
                {errorss.phone.length > 0 && (
                  <h5 className="errornome">{errorss.phone}</h5>
                )}
              </div>

              <SobreEstabelecimento>
                {aboutTheEstablishments}
              </SobreEstabelecimento>
              <div className="form-group">
                <div>
                  <input
                    name="razaosocial"
                    placeholder={nameOfTheEstablishment}
                    className="razaosocial"
                    value={data.razaosocial}
                    onChange={(e) =>
                      setData({ ...data, razaosocial: e.target.value })
                    }
                    onBlur={(e) => {
                      if (e.target.value.length === 0) {
                        setErrors({
                          ...errorss,
                          razaosocial: enterTheNameOfYourEstablishment,
                        });
                        setSend(false);
                      } else {
                        setErrors({
                          ...errorss,
                          razaosocial: "",
                        });
                      }
                    }}
                    ref={register}
                  />
                  <br />
                  {errorss.razaosocial.length > 0 && (
                    <h5 className="errornome">{errorss.razaosocial}</h5>
                  )}
                </div>

                <div>
                  <InputMasked
                    name="cnpj"
                    placeholder="CNPJ*"
                    className="cnpj"
                    mask="cnpj"
                    value={data.cnpj}
                    maxLength="18"
                    onChange={(e) => setData({ ...data, cnpj: e.target.value })}
                    onBlur={(e) => {
                      if (e.target.value.length === 0) {
                        setErrors({
                          ...errorss,
                          cnpj: enterYourCNPJ,
                        });
                        setSend(false);
                      } else if (
                        e.target.value.length === 18 &&
                        !isValidCNPJ(data.cnpj.replace(/[^\d]+/g, ""))
                      ) {
                        setErrors({
                          ...errorss,
                          cnpj: enterAValidCNPJ,
                        });
                      } else {
                        setErrors({
                          ...errorss,
                          cnpj: "",
                        });
                      }
                    }}
                    ref={register}
                  />

                  <br />
                  {errorss.cnpj.length > 0 && (
                    <h5 className="errornome">{errorss.cnpj}</h5>
                  )}
                </div>
              </div>
              <div className="form-one top">
                <div>
                  <input
                    className="url"
                    name="url"
                    placeholder={chooseAURLForYourMenu}
                    value={`${prefixInput}${url}`}
                    onFocus={() =>
                      setPrefixInput("https://app.mytripmenu.com/")
                    }
                    onBlur={() => {
                      if (url.length === 0) {
                        setPrefixInput("");
                        setUrlError(pleaseEnterAValidSubdomain);
                      } else {
                        setUrlError("");
                      }
                    }}
                    onChange={(e) => setUrl(e.target.value.substr(27))}
                    ref={register}
                  />
                  
                  <br />
                  {urlError.length > 0 && (
                    <h5 className="urlerror">{urlError}</h5>
                  )}
                  <br />
                  {
                  console.log(errorss)
                  }
                  {errorss.subdomain.length > 0 && (
                    <h5 className="errornome">{errorss.subdomain}</h5>
                  )}
                </div>
              </div>
              <div className="form-one">
                <div>
                  <input
                    type="email"
                    name="gerenciador"
                    placeholder={emailForLogosInTheManager}
                    className="gerenciador"
                    value={data.gerenciador}
                    onChange={(e) =>
                      setData({ ...data, gerenciador: e.target.value })
                    }
                    onBlur={(e) => {
                      if (e.target.value.length === 0) {
                        setErrors({
                          ...errorss,
                          gerenciador: enterAValidEmail,
                        });
                        setSend(false);
                      } else {
                        setErrors({
                          ...errorss,
                          gerenciador: "",
                        });
                      }
                    }}
                    ref={register}
                  />
                  <br />
                  {errorss.gerenciador.length > 0 && (
                    <h5 className="errornome">{errorss.gerenciador}</h5>
                  )}
                </div>
              </div>
              <div className="button-submit">
                <button type="submit">{start}</button>
                {errorSubmit.length > 0 && (
                  <h5 className="errornome">{errorSubmit}</h5>
                )}
              </div>
            </FormContainer>
          </ContainerForm>
        </div>
        <div className="img">
          <img src={iphone05} alt="" />
        </div>
      </ContainerWeb>
      <ContainerMobile>
        <Container>
          <Sobre>{aboutYou}</Sobre>
          <div className="required">
            <Asterisco>*</Asterisco>
            <Preenchimento>{mandatoryFilling}</Preenchimento>
          </div>
        </Container>
        <FormContainer onBlur={handleVerify} onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <div>
              <input
                type="text"
                name="name"
                placeholder={firstName}
                className="nome"
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                onBlur={(e) => {
                  if (e.target.value.length === 0) {
                    setErrors({ ...errorss, name: enterYourName });
                    setSend(false);
                  } else {
                    setErrors({
                      ...errorss,
                      name: "",
                    });
                  }
                }}
                ref={register}
              />
              <br />
              {errorss.name.length > 0 && (
                <h5 className="errornome">{errorss.name}</h5>
              )}
            </div>
            <div>
              <input
                type="text"
                name="surname"
                placeholder={secondName}
                className="sobrenome"
                value={data.surname}
                onChange={(e) => setData({ ...data, surname: e.target.value })}
                onBlur={(e) => {
                  if (e.target.value.length === 0) {
                    setErrors({
                      ...errorss,
                      surname: { enterYourSecondName },
                    });
                    setSend(false);
                  } else {
                    setErrors({
                      ...errorss,
                      surname: "",
                    });
                  }
                }}
                ref={register}
              />
              <br />
              {errorss.surname.length > 0 && (
                <h5 className="errornome">{errorss.surname}</h5>
              )}
            </div>
          </div>

          <div className="form-one top">
            <input
              name="email"
              placeholder={contactEmail}
              className="email"
              type="email"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              onBlur={(e) => {
                if (e.target.value.length === 0) {
                  setErrors({
                    ...errorss,
                    email: enterYourEmail,
                  });
                  setSend(false);
                } else {
                  setErrors({
                    ...errorss,
                    email: "",
                  });
                }
              }}
              ref={register}
            />
            <br />
            {errorss.email.length > 0 && (
              <h5 className="errornome">{errorss.email}</h5>
            )}
          </div>

          <div className="form-one">
            <InputMasked
              name="whatsapp"
              placeholder={mobileWhatsapp}
              className="whatsapp"
              mask="phone"
              value={data.phone}
              onChange={(e) => setData({ ...data, phone: e.target.value })}
              onBlur={(e) => {
                setDataFormated({ ...dataFormated, phone: e.target.value });
                if (e.target.value.length === 0) {
                  setErrors({
                    ...errorss,
                    phone: enterYourContactNumber,
                  });
                  setSend(false);
                } else if (
                  (e.target.value.length === 16 ||
                    e.target.value.length === 15) &&
                  !isValidPhone(e.target.value.replace(/[^0-9]+/g, ""))
                ) {
                  setErrors({
                    ...errorss,
                    phone: pleaseEnterYourValidNumber,
                  });
                  setSend(false);
                } else {
                  setErrors({
                    ...errorss,
                    phone: "",
                  });
                }
              }}
              ref={register}
            />
            <br />
            {errorss.phone.length > 0 && (
              <h5 className="errornome">{errorss.phone}</h5>
            )}
          </div>

          <SobreEstabelecimento>{aboutTheEstablishments}</SobreEstabelecimento>
          <div className="form-group">
            <div>
              <input
                name="razaosocial"
                placeholder={nameOfTheEstablishment}
                className="razaosocial"
                value={data.razaosocial}
                onChange={(e) =>
                  setData({ ...data, razaosocial: e.target.value })
                }
                onBlur={(e) => {
                  if (e.target.value.length === 0) {
                    setErrors({
                      ...errorss,
                      razaosocial: enterTheNameOfYourEstablishment,
                    });
                    setSend(false);
                  } else {
                    setErrors({
                      ...errorss,
                      razaosocial: "",
                    });
                  }
                }}
                ref={register}
              />
              <br />
              {errorss.razaosocial.length > 0 && (
                <h5 className="errornome">{errorss.razaosocial}</h5>
              )}
            </div>

            <div>
              <InputMasked
                name="cnpj"
                placeholder="CNPJ*"
                className="cnpj"
                mask="cnpj"
                value={data.cnpj}
                maxLength="18"
                onChange={(e) => setData({ ...data, cnpj: e.target.value })}
                onBlur={(e) => {
                  if (e.target.value.length === 0) {
                    setErrors({
                      ...errorss,
                      cnpj: enterYourCNPJ,
                    });
                    setSend(false);
                  } else if (
                    e.target.value.length !== 18 &&
                    !isValidCNPJ(data.cnpj.replace(/[^\d]+/g, ""))
                  ) {
                    setErrors({
                      ...errorss,
                      cnpj: enterAValidCNPJ,
                    });
                  } else {
                    setErrors({
                      ...errorss,
                      cnpj: "",
                    });
                  }
                }}
                ref={register}
              />

              <br />
              {errorss.cnpj.length > 0 && (
                <h5 className="errornome">{errorss.cnpj}</h5>
              )}
            </div>
          </div>
          <div className="form-one top">
            <div>
              <input
                className="url"
                name="url"
                placeholder={chooseASubdomainForYourMenu}
                value={`${prefixInput}${url}`}
                onFocus={() => setPrefixInput("https://app.mytripmenu.com/")}
                onBlur={() => {
                  if (url.length === 0) {
                    setPrefixInput("");
                    setUrlError(pleaseEnterAValidSubdomain);
                  } else {
                    setUrlError("");
                  }
                }}
                onChange={(e) => setUrl(e.target.value.substr(27))}
                ref={register}
              />
              <br />
              {urlError.length > 0 && <h5 className="urlerror">{urlError}</h5>}
            </div>
          </div>
          <div className="form-one">
            <div>
              <input
                type="email"
                name="gerenciador"
                placeholder={emailForLogosInTheManager}
                className="gerenciador"
                value={data.gerenciador}
                onChange={(e) =>
                  setData({ ...data, gerenciador: e.target.value })
                }
                onBlur={(e) => {
                  if (e.target.value.length === 0) {
                    setErrors({
                      ...errorss,
                      gerenciador: enterAValidEmail,
                    });
                    setSend(false);
                  } else {
                    setErrors({
                      ...errorss,
                      gerenciador: "",
                    });
                  }
                }}
                ref={register}
              />
              <br />
              {errorss.gerenciador.length > 0 && (
                <h5 className="errornome">{errorss.gerenciador}</h5>
              )}
            </div>
          </div>
          <div className="button-submit">
            <button type="submit">{start}</button>
            {errorSubmit.length > 0 && (
              <h5 className="errornome">{errorSubmit}</h5>
            )}
          </div>
        </FormContainer>
      </ContainerMobile>
    </>
  );
};

export default FormGeneral;
