import React from "react";

import { Container, Titulo, Elemento12, Elemento11, Subtitulo } from "./styles";

import elemento11 from "../../assets/images8/elemento11.png";
import elemento12 from "../../assets/images8/elemento12.png";
import TranslatorFunction from '../I18n/Translator';

function FormMobile({ LitleDivisor }) {

  const freeTtrialFor30days = TranslatorFunction({ path: 'freeTtrialFor30days.message' });
  const makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow = TranslatorFunction({ path: 'makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow.message' });

  return (
    <Container>
      <Elemento11 src={elemento11} />
      <Elemento12 src={elemento12} />
      <Titulo>{freeTtrialFor30days}</Titulo>
      <LitleDivisor />
      <Subtitulo>
        {makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow}
      </Subtitulo>
    </Container>
  );
}

export default FormMobile;
