import React from "react";
import iphone3 from "../../assets/iphone3_web.png";
import elemento15 from "../../assets/elemento15_web.png";
import IconWpp from "../../assets/icon_wpp.svg";
import iconTranslator from "../../assets/images8/translate.svg";
import fotos from "../../assets/images3/fotos.svg";
import toogles from "../../assets/images3/toogles.svg";
import money from "../../assets/images3/money.svg";
import news from "../../assets/images3/news.svg";

import { Container, Texts, Content } from "./styles";
import TranslatorFunction from "../I18n/Translator";

const Section3 = ({ id }) => {
  const aDigitalSolutionForYourCompany = TranslatorFunction({
    path: "aDigitalSolutionForYourCompany.message",
  });
  const getAccessToASeriesOfFeaturesThatWillMakeManagementEasierOfYourBusiness =
    TranslatorFunction({
      path: "getAccessToASeriesOfFeaturesThatWillMakeManagementEasierOfYourBusiness.message",
    });
  const receiveYourOrdersByWhatsApp = TranslatorFunction({
    path: "receiveYourOrdersByWhatsApp.message",
  });
  const fullMenuWithPhotos = TranslatorFunction({
    path: "fullMenuWithPhotos.message",
  });
  const activateAndDeactivateItemsWithEase = TranslatorFunction({
    path: "activateAndDeactivateItemsWithEase.message",
  });
  const realTimePriceUpdate = TranslatorFunction({
    path: "realTimePriceUpdate.message",
  });
  const testNewProductsQuickly = TranslatorFunction({
    path: "testNewProductsQuickly.message",
  });
  const upToTenLanguagesAvailable = TranslatorFunction({
    path: "upToTenLanguagesAvailable.message",
  });

  return (
    <Container id={id}>
      <img src={elemento15} className="element01" alt="" />
      <Texts>
        <h1>{aDigitalSolutionForYourCompany}</h1>
        <div className="tab" />
        <h2>
          {
            getAccessToASeriesOfFeaturesThatWillMakeManagementEasierOfYourBusiness
          }
        </h2>
      </Texts>
      <Content>
        <div className="icons-one">
          <div>
            <h4>{upToTenLanguagesAvailable}</h4>
            <img src={iconTranslator} alt="" />
          </div>
          <div>
            <h4>{fullMenuWithPhotos}</h4>
            <img src={fotos} alt="" />
          </div>
          <div>
            <h4>{activateAndDeactivateItemsWithEase}</h4>
            <img src={toogles} alt="" />
          </div>
        </div>
        <div className="picture-iphone">
          <img src={iphone3} alt="" />
        </div>
        <div className="icons-two">
          <div>
            <img src={IconWpp} alt="" />
            <h4>{receiveYourOrdersByWhatsApp}</h4>
          </div>
          <div>
            <img src={money} alt="" />
            <h4>{realTimePriceUpdate}</h4>
          </div>
          <div>
            <img src={news} alt="" />
            <h4>{testNewProductsQuickly}</h4>
          </div>
        </div>
      </Content>
    </Container>
  );
};

export default Section3;
