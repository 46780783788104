import styled from "styled-components";
import qr from "../../assets/images2/qr1.png";

export const Container = styled.div`
  display: flex;
  margin-top: 48px;

  .iphone-bg {
    width: 47.15vw;
    background-image: url(${qr});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: -6%;
  }
  button {
    width: 70%;
    margin: 8px auto;
  }
`;

export const Clientes = styled.h4`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #5d5d5d;
  width: 85%;
  margin-left: 10px;
  text-align: right;
  margin-bottom: 24px;
`;

export const QrCode = styled.img`
  width: 47.15vw;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  object-fit: contain;
`;

export const Button = styled.button`
  cursor: pointer;
  width: 201px;
  height: 61px;
  background: #ef7522;
  border: 1px solid #ef7522;
  box-sizing: border-box;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  line-height: 18px;
  font-size: 15px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: 26px;
  @media (max-width: 376px) {
    width: 156px;
    height: 50px;
  }
`;

export const ClientesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  .title {
    width: 85%;
    margin-left: 10px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    margin-bottom: 22px;
    text-align: right;
    color: #7b7b7b;
  }
  h2 {
    font-size: 16px;
  }
  button {
    margin-bottom: 16px;
  }
`;
