import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
`;

export const Titulo = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 35px;
  color: #959595;
`;

export const Digital = styled.h2`
  margin-left: 6px;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 35px;
  text-align: right;
  color: #959595;
`;

export const Espacamento = styled.img`
  width: 34px;
  height: 2px;
  background: #ef7522;
`;

export const Submenu = styled.h4`
  margin-top: 8px;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #959595;
`;

export const ContainerTexts = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const Notebook = styled.img`
  width: 60%;
`;

export const Passos = styled.img`
  width: 100%;
`;

export const ContainerImages = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 56px;
  button {
    width: 50%;
  }
  .left {
    position: absolute;
    left: 0;
    z-index: -10;
  }
  .right {
    position: absolute;
    right: 0;
    z-index: -10;
  }
  a {
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
  }
`;
