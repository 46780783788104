import React from "react";

import Button from "../Button";
import {
  Container,
  Notebook,
  Passos,
  Submenu,
  ContainerTexts,
  Titulo,
  Digital,
  ContainerImages,
} from "./styles";
import notebook from "../../assets/images5/notebook1.png";
import passos1 from "../../assets/images5/passos1.png";
import elemento06 from "../../assets/images5/elemento06.png";
import elemento07 from "../../assets/images5/elemento07.png";
import TranslatorFunction from '../I18n/Translator';

function FastDigitalMobile({ LitleDivisor }) {

  const simple = TranslatorFunction({ path: 'simple.message' });
  const easy = TranslatorFunction({ path: 'easy.message' });
  const digital = TranslatorFunction({ path: 'digital.message' });
  const yourMenuIsReadyInMinutes = TranslatorFunction({ path: 'yourMenuIsReadyInMinutes.message' });
  const loginNow = TranslatorFunction({ path: 'loginNow.message' });

  return (
    <Container>
      <ContainerTexts>
        <Titulo>{simple}. {easy}. </Titulo>
        <Digital>{digital}.</Digital>
      </ContainerTexts>

      <LitleDivisor />
      <Submenu>{yourMenuIsReadyInMinutes}</Submenu>

      <Notebook src={notebook} />
      <Passos src={passos1} />
      <ContainerImages>
        <img src={elemento07} className="right" alt="" />
        <a
          href={`${process.env.REACT_APP_LOGIN}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button>
            <h4>{loginNow}</h4>
          </Button>
        </a>

        <img src={elemento06} className="left" alt="" />
      </ContainerImages>
    </Container>
  );
}

export default FastDigitalMobile;
