import React from "react";
import { Link } from "react-scroll";

import { Logo, Menu, MenuBar, MenuBarNav } from "./styles";
import logo from "../../assets/logo_mtm_color.png";
import menu from "../../assets/images/menu.svg";
import TranslatorFunction from '../I18n/Translator';
import I18n from '../../components/I18n';

function HeaderWeb() {

  const about = TranslatorFunction({ path: 'about.message' });
  const solution = TranslatorFunction({ path: 'solution.message' });
  const functionality = TranslatorFunction({ path: 'functionality.message' });
  const whoUse = TranslatorFunction({ path: 'whoUse.message' });
  const prices = TranslatorFunction({ path: 'prices.message' });
  const login = TranslatorFunction({ path: 'login.message' });
  const register = TranslatorFunction({ path: 'register.message' });

  return (
    <MenuBar>
      <Logo src={logo} alt="MyTripMenu" />
      <Menu src={menu} alt="Menu" />
      <MenuBarNav>
      
        <Link to="about" smooth={true}>
          {about}
        </Link>
        <Link to="solution" smooth={true}>
          {solution}
        </Link>
        <Link to="functionality" smooth={true}>
          {functionality}
        </Link>
        <Link to="whouse" smooth={true}>
          {whoUse}
        </Link>
        <Link to="price" smooth={true}>
          {prices}
        </Link>
        <I18n />
        <div className="login">
          <a
            href={`${process.env.REACT_APP_LOGIN}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>{login}</button>
          </a>

          <Link to="register" smooth={true} className="cadastro">
            {register}
          </Link>
        </div>
      </MenuBarNav>
    </MenuBar>
  );
}

export default HeaderWeb;
