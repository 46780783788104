import React, { useCallback } from "react";

import { cnpj, phone } from "./masks";

const InputMasked = React.forwardRef(({ mask, ...props }, ref) => {
  const handleKeyUp = useCallback(
    (e) => {
      if (mask === "phone") {
        phone(e);
      }
      if (mask === "cnpj") {
        cnpj(e);
      }
    },
    [mask]
  );
  return <input ref={ref} {...props} onKeyUp={handleKeyUp} />;
});

export default InputMasked;
