import styled from "styled-components";

export const Container = styled.div`
  margin-top: 52px;
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Titulo = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 0px;
  text-align: center;
  color: #5d5d5d;
  height: 32px;
`;

export const Elemento11 = styled.img`
  position: absolute;
  left: 0;
  z-index: -10;
`;

export const Elemento12 = styled.img`
  position: absolute;
  right: 0;
  z-index: -10;
`;

export const Espacamento = styled.div`
  margin-top: 40px;
  margin-bottom: 18px;
  width: 34px;
  height: 2px;
  background-color: #ff8815;
`;

export const Subtitulo = styled.h4`
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  width: 80%;
  margin: 12px auto;
  color: #959595;
  z-index: 1;
`;
