import React from "react";
import LogoCinza from "../../assets/logo_cinza.png";
import instagram from "../../assets/images8/instagram.svg";
import facebook from "../../assets/images8/facebook.svg";
import twitter from "../../assets/images8/twitter.svg";
import site from "../../assets/images8/site.svg";

import {
  Contatos,
  Logo,
  RedesSociais,
  Instagram,
  Facebook,
  Twitter,
  Site,
  Container,
} from "./style";

const Footer = () => {
  return (
    <Container>
      <Contatos>
        <Logo src={LogoCinza} />
        <RedesSociais>
          <Instagram
            src={instagram}
            onClick={() => window.open("https://www.instagram.com/mytripmenu/")}
          />
          <Site
            src={site}
            onClick={() => window.open("https://www.mytripmenu.com/")}
          />
        </RedesSociais>
      </Contatos>
    </Container>
  );
};

export default Footer;
