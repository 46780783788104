export function phone(event) {
  const sanitizedInput = event.target.value.replace(/\D/g, "").slice(0, 11);
  let maskedInput = sanitizedInput.split("");

  if (maskedInput.length > 0) maskedInput.splice(0, 0, "(");
  if (maskedInput.length > 3) maskedInput.splice(3, 0, ")");
  if (maskedInput.length > 4) maskedInput.splice(4, 0, " ");
  if (maskedInput.length > 9) maskedInput.splice(10, 0, "-");
  // if (maskedInput.length > 14) {
  //   maskedInput.splice(9, 1);
  //   maskedInput.splice(10, 0, "-");
  // }
  event.target.value = maskedInput.join("");
  return event;
}

export function cnpj(event) {
  const sanitizedInput = event.target.value.replace(/\D/g, "").slice(0, 14);
  let maskedInput = sanitizedInput.split("");

  if (maskedInput.length > 2) maskedInput.splice(2, 0, ".");
  if (maskedInput.length > 6) maskedInput.splice(6, 0, ".");
  if (maskedInput.length > 10) maskedInput.splice(10, 0, "/");
  if (maskedInput.length > 15) maskedInput.splice(15, 0, "-");

  event.target.value = maskedInput.join("");
  return event;
}
