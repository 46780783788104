/* eslint-disable import/no-anonymous-default-export */
export default {
  translations: {
    language: {
      message: "zh-Hant",
    },
    theSmartestSolutionToPresentYourMenu: {
      message: "展示菜單的最智能解決方案",
    },
    digitalMenu: {
      message: "最聰明的方式來展示您的菜單。",
    },
    ordersBy: {
      message: "訂單由",
    },
    myTripMenuisADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphoneSimpleEasyAndFast: {
      message: "My Trip Menu 是一個帶照片的數字菜單，可直接從客戶的智能手機上通過二維碼訪問。簡單、容易、快速。",
    },
    knowMore: {
      message: "了解更多",
    },
    thePerfectSolutionForYourSecurityProtocols: {
      message: "您的安全協議的完美解決方案",
    },
    withMyTripYouCreateASafeEnvironmentAndAvoidTransmissionOfTheNewCoronavirus: {
      message: "通過 My Trip，您可以創造一個安全的環境並防止新型冠狀病毒的傳播。",
    },
    noAppNeeded: {
      message: "無需應用程序",
    },
    directAccessByQRCode: {
      message: "二維碼直接訪問",
    },
    yourCustomersWontNeedToDownloadOneMoreapplicationJustScanTheQRCodeThroughTheCameraSmartphoneAndThatsIt: {
      message: "您的客戶無需再下載另一個應用程序。 只需通過智能手機攝像頭掃描二維碼，就可以了！",
    },
    start: {
      message: "開始",
    },
    sellMore: {
      message: "多賣",
    },
    and: {
      message: "和",
    },
    save: {
      message: "節省",
    },
    withPrintingCosts: {
      message: "加上印刷費用。",
    },
    freeTrial: {
      message: "免費試用",
    },
    days: {
      message: "天",
    },
    theMostSolution: {
      message: "最解",
    },
    smartToPresent: {
      message: "聰明的呈現",
    },
    yourMenu: {
      message: "你的菜單",
    },
    myTripMenuIsADigitalMenuWithPhotos: {
      message: "我的旅行菜單是帶有照片的數字菜單",
    },
    accessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: "直接從客戶的智能手機通過二維碼訪問",
    },
    myTripMenuIsADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: "我的旅行菜單是帶有照片的數字菜單，可通過客戶智能手機上的二維碼直接訪問。",
    },
    simpleEasyAndFast: {
      message: "簡單、輕鬆、快速",
    },
    simple: {
      message: "簡單、輕鬆、快速",
    },
    easy: {
      message: "簡單的",
    },
    fast: {
      message: "快速地",
    },
    digital: {
      message: "數字的",
    },
    receiveYourOrders: {
      message: "收到您的訂單",
    },
    by: {
      message: "每",
    },
    automateTheService: {
      message: "自動化服務",
    },
    ofYourDelivery: {
      message: "你的交貨",
    },
    automateYourDeliveryService: {
      message: "自動化您的送貨服務",
    },

    yourCustomersAccessA: {
      message: "您的客戶訪問",
    },
    linkWithYourDigitalMenu: {
      message: "與您的數字菜單鏈接,",
    },
    chooseTheProductsAndYou: {
      message: "選擇產品和你",
    },
    receiveTheOrderCirectlyOn: {
      message: "直接收到訂單",
    },
    speed: {
      message: "速度",
    },
    agility: {
      message: "敏捷",
    },
    orders: {
      message: "要求",
    },
    profits: {
      message: "利潤",
    },
    testServiceLink: {
      message: "測試服務鏈接",
    },
    sellMoreAndSaveOnPrintingCosts: {
      message: "賣得更多，節省印刷成本.",
    },
    aDigitalSolution: {
      message: "數字解決方案",
    },
    forYourCompany: {
      message: "為您的公司",
    },

    getAccessToARangeOfFeatures: {
      message: "訪問一系列功能",
    },
    thatWillFacilitateTheManagementOfYourBusiness: {
      message: "這將有助於您的業務管理。",
    },
    fullMenuWithPhotos: {
      message: "帶照片的完整菜單",
    },
    activateAndDeactivateItemsWithEase: {
      message: "輕鬆激活和停用項目",
    },
    realTimePriceUpdate: {
      message: "實時價格更新",
    },
    testNewProductsQuickly: {
      message: "快速測試新產品",
    },
    selectionFiltersByProductType: {
      message: "按產品類型篩選",
    },
    yourMenuIsReadyInMinutes: {
      message: "您的菜單在幾分鐘內就準備好了！",
    },
    loginNow: {
      message: "現在登錄",
    },
    registrationSuccessful: {
      message: "註冊成功！",
    },
    checkYourEmailInboxAndYourSpam: {
      message: "檢查您的電子郵件收件箱和垃圾郵件。",
    },
    pleaseEnterAValidSubdomain: {
      message: "請輸入有效的子域。",
    },
    checkAllFields: {
      message: "檢查所有字段。",
    },
    existingEmailTryAgain: {
      message: "現有電子郵件。 再試一次。",
    },
    invalidEmailTryAgain: {
      message: "不合規電郵。 再試一次",
    },
    invalidLastNameTryAgain: {
      message: "姓氏無效。 再試一次",
    },
    invalidNumberTryAgain: {
      message: "無效號碼。 再試一次",
    },
    subdomainAlreadyExistsTryAgain: {
      message: "子域已經存在。 再試一次。",
    },
    invalidSubdomainTryAgain: {
      message: "無效的子域。 再試一次。",
    },
    invalidCNPJTryAgain: {
      message: "CNPJ 無效。 再試一次。",
    },

    CNPJAlreadyRegisteredTryAgain: {
      message: "CNPJ 已經註冊。 再試一次。",
    },
    somethingUnexpectedHappenedTryAgainLater: {
      message: "意想不到的事情發生了。 稍後再試。",
    },
    freeTtrialFor30days: {
      message: "免費試用30天！",
    },
    makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow: {
      message: "立即註冊並體驗我的旅行菜單的設施",
    },
    makeYourRegistration: {
      message: "進行註冊",
    },
    aboutYou: {
      message: "關於你",
    },
    mandatoryFilling: {
      message: "強制填寫",
    },
    name: {
      message: "姓名*",
    },
    lastName: {
      message: "姓*",
    },
    enterYourLastName: {
      message: "輸入您的姓氏.",
    },
    firstName: {
      message: "名*",
    },
    enterYourName: {
      message: "輸入你的名字。",
    },
    secondName: {
      message: "第二個名字*",
    },
    enterYourSecondName: {
      message: "輸入您的中間名.",
    },
    contactEmail: {
      message: "聯繫電子郵件*",
    },
    enterYourEmail: {
      message: "輸入你的電子郵箱.",
    },
    mobileWhatsapp: {
      message: "手機/Whatsapp",
    },
    enterYourContactNumber: {
      message: "輸入您的聯繫電話。",
    },
    pleaseEnterYourValidNumber: {
      message: "請輸入您的有效號碼。",
    },
    aboutTheEstablishments: {
      message: "關於場所",
    },
    nameOfTheEstablishment: {
      message: "機構名稱*",
    },
    enterTheNameOfYourEstablishment: {
      message: "輸入您的機構名稱。",
    },
    enterYourCNPJ: {
      message: "輸入您的 CNPJ。",
    },
    enterAValidCNPJ: {
      message: "輸入有效的 CNPJ。",
    },
    chooseAURLForYourMenu: {
      message: "為您的菜單選擇一個 URL*",
    },
    emailForLogosInTheManager: {
      message: "管理器中徽標的電子郵件*",
    },
    enterAValidEmail: {
      message: "輸入有效的電子郵件",
    },
    chooseASubdomainForYourMenu: {
      message: "為您的菜單選擇一個子域*",
    },
    about: {
      message: "關於",
    },
    solution: {
      message: "解決方案",
    },
    functionality: {
      message: "功能性",
    },
    whoUse: {
      message: "誰用？",
    },
    prices: {
      message: "價格",
    },
    login: {
      message: "登錄",
    },
    register: {
      message: "登記",
    },
    yourCustomersAccessALinkWithYourDigitalMenuChooseTheProductsAndYouReceiveTheOrderDirectlyOnWhatsApp: {
      message: "您的客戶通過您的數字菜單訪問鏈接，選擇產品，然後您直接在 WhatsApp 上收到訂單。",
    },
    digitalMenuModule: {
      message: "數字菜單模塊",
    },
    testFor: {
      message: "測試通過",
    },
    afterThisPeriodPayOnlyR99PerMonth: {
      message: "在此期間之後，每月只需支付 99 雷亞爾。",
    },
    enjoy: {
      message: "享受!",
    },
    month: {
      message: "月",
    },
    annualPlan: {
      message: "年度計劃",
    },
    aDigitalSolutionForYourCompany: {
      message: "適合您公司的數字解決方案",
    },
    getAccessToASeriesOfFeaturesThatWillMakeManagementEasierOfYourBusiness: {
      message: "訪問一系列功能，讓您的業務管理更加輕鬆。",
    },
    receiveYourOrdersByWhatsApp: {
      message: "通過 WhatsApp 接收您的訂單",
    },
    accessTheDashboard: {
      message: "訪問儀表板",
    },
    registerYourProducts: {
      message: "註冊您的產品",
    },
    publishToYourCustomers: {
      message: "發布給您的客戶",
    },
    whoUsesMyTripMenu: {
      message: "誰使用我的旅行菜單？",
    },
    anIntelligentSolutionForDifferentSegments: {
      message: "針對不同細分市場的智能解決方案。",
    },
    restaurants: {
      message: "餐廳",
    },
    coffeeShops: {
      message: "咖啡店",
    },
    fastFoods: {
      message: "快餐",
    },
    bars: {
      message: "快餐",
    },
    pubs: {
      message: "酒館",
    },
    foodTrucks: {
      message: "食品卡車",
    },
    bakeries: {
      message: "麵包店",
    },
    pizzerias: {
      message: "比薩店",
    },
    companyName: {
      message: "公司名稱*",
    },
    enterYourCompanyName: {
      message: "輸入您的公司名稱。",
    },
    yourCustomersWontNeedToDownloadYetAnotherAppJustScanTheQRCodeThroughTheSmartphoneCameraAndThatsIt: {
      message: "您的客戶無需再下載另一個應用程序。 只需通過智能手機攝像頭掃描二維碼，就可以了！",
    },
    digitizeYourMenu: {
      message: "數字化您的菜單！",
    },
    moreThan30LanguagesAvailable: {
      message: "超過 30 種語言可用",
    },
    yourMenuReadyForTheWorld: {
      message: "您的菜單已準備好迎接世界",
    },
    quote: {
      message: '用新產品給菜單增氧總是會為更改菜單產生高昂的打印成本。 通過“我的旅行菜單”，我們添加了一種新飲品，並在最初幾天售出了 100 多份。',
    },
    quote2: {
      message: '愛德華多 - 哈卡酒吧',
    },
    multilingual: {
      message: "多種語言"
    },
    upToTenLanguagesAvailableForYourCustomer: {
      message: "多達 11 種語言可供您的客戶使用"
    },
    portugueseDotJapaneseDotSpanishDotEnglishDotGermanDotItalianDotChineseDotKoreanDotRussianDotArabic: {
      message: "葡萄牙語 • 日語 • 西班牙語 • 英語 • 德語 • 意大利語 • 中文 • 韓語 • 俄語 • 阿拉伯語 • 弗朗西斯" 
    },
    oneClickTranslation: {
      message: "一鍵翻譯"
    },
    upToTenLanguagesAvailable: {
      message: "多達10種語言可用"
    }
  },
};
