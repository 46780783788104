import styled from 'styled-components';

const DivI18n = styled.div`
    z-index: 1000000;
    margin-right: 40px;
`



export { DivI18n};
