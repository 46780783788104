import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;

export const ContainerGrey = styled.div`
  width: 100%;
  background-color: #efefef;
  position: relative;
`;

export const ContainerGreyMobile = styled.div`
  width: 100%;
  position: relative;
  @media (min-width: 1000px) {
    display: none;
  }
`;

export const ContainerGreyWeb = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
  width: 100%;
  position: relative;
`;

export const ContainerCardapioMobile = styled.div`
  @media (min-width: 1000px) {
    display: none;
  }
`;

export const ContainerCardapioWeb = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
`;
