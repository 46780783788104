/* eslint-disable import/no-anonymous-default-export */
export default {
  translations: {

    language: {
      message: 'es',
    },
    theSmartestSolutionToPresentYourMenu: {
      message: 'La solución más inteligente para presentar tu menú',
    },
    digitalMenu: {
      message: 'Menú digital',
    },
    ordersBy: {
      message: 'Pedidos por',
    },
    myTripMenuisADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphoneSimpleEasyAndFast: {
      message: 'My Trip Menu es un menú digital con fotos, al que se accede por Código QR directamente desde el smartphone del cliente. Sencillo, fácil y rápido.',
    },
    knowMore: {
      message: 'saber más',
    },
    thePerfectSolutionForYourSecurityProtocols: {
      message: 'La solución perfecta para tus protocolos de seguridad',
    },
    withMyTripYouCreateASafeEnvironmentAndAvoidTransmissionOfTheNewCoronavirus: {
      message: 'Con My Trip creas un entorno seguro y evitas la transmisión del nuevo coronavirus.',
    },
    noAppNeeded: {
      message: 'No se necesita aplicación',
    },
    directAccessByQRCode: {
      message: 'Acceso directo por Código QR',
    },
    yourCustomersWontNeedToDownloadOneMoreapplicationJustScanTheQRCodeThroughTheCameraSmartphoneAndThatsIt: {
      message: 'Sus clientes no necesitarán descargar otra aplicación más. Simplemente escanee el código QR, a través de la cámara del teléfono inteligente, ¡y listo!',
    },
    start: {
      message: 'Comienzo',
    },
    sellMore: {
      message: 'Vender más',
    },
    and: {
      message: 'y',
    },
    save: {
      message: 'ahorrar',
    },
    withPrintingCosts: {
      message: 'en los costos de impresión',
    },
    freeTrial: {
      message: 'Prueba gratis',
    },
    days: {
      message: 'días',
    },
    theMostSolution: {
      message: 'La forma más ',
    },
    smartToPresent: {
      message: 'inteligente de presentar',
    },
    yourMenu: {
      message: 'tu menú',
    },
    myTripMenuIsADigitalMenuWithPhotos: {
      message: 'My Trip Menu es un menú digital con fotos',
    },
    accessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: 'se accede por código QR directamente desde el teléfono inteligente del cliente',
    },
    myTripMenuIsADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: 'My Trip Menu es un menú digital con fotos, al que se accede por Código QR directamente desde el smartphone del cliente.',
    },
    simpleEasyAndFast: {
      message: 'Sencillo, fácil y rápido',
    },
    simple: {
      message: 'Sencillo',
    },
    easy: {
      message: 'Fácil',
    },
    fast: {
      message: 'rápido',
    },
    digital: {
      message: 'Digital',
    },
    receiveYourOrders: {
      message: 'Recibe tus pedidos',
    },
    by: {
      message: 'por',
    },
    automateTheService: {
      message: 'Automatice su',
    },
    ofYourDelivery: {
      message: 'servicio de entrega',
    },
    automateYourDeliveryService: {
      message: 'Automatice su servicio de entrega',
    },
    yourCustomersAccessA: {
      message: 'Tus clientes acceden a un',
    },
    linkWithYourDigitalMenu: {
      message: 'enlace con tu carta digital,',
    },
    chooseTheProductsAndYou: {
      message: 'eligen los productos y ',
    },
    receiveTheOrderCirectlyOn: {
      message: 'recibes el pedido directamente en',
    },
    speed: {
      message: 'Velocidad',
    },
    agility: {
      message: 'Agilidad',
    },
    orders: {
      message: 'Pedidos',
    },
    profits: {
      message: 'Ganancias',
    },
    testServiceLink: {
      message: 'Enlace de servicio de prueba',
    },
    sellMoreAndSaveOnPrintingCosts: {
      message: 'Vende más y ahorra en costes de impresión.',
    },
    aDigitalSolution: {
      message: 'Una solución digital',
    },
    forYourCompany: {
      message: 'para tu empresa',
    },

    getAccessToARangeOfFeatures: {
      message: 'Accede a una serie de funcionalidades',
    },
    thatWillFacilitateTheManagementOfYourBusiness: {
      message: 'que te facilitarán la gestión de tu negocio.',
    },
    fullMenuWithPhotos: {
      message: 'Menú completo con fotos.',
    },
    activateAndDeactivateItemsWithEase: {
      message: 'Activa y desactiva elementos con facilidad',
    },
    realTimePriceUpdate: {
      message: 'Actualización de precios en tiempo real',
    },
    testNewProductsQuickly: {
      message: 'Pruebe nuevos productos rápidamente',
    },
    selectionFiltersByProductType: {
      message: 'Filtros de selección por tipo de producto',
    },
    yourMenuIsReadyInMinutes: {
      message: '¡Tu menú está listo en minutos!',
    },
    loginNow: {
      message: 'Inicia sesión ahora',
    },
    registrationSuccessful: {
      message: '¡Registro exitoso!',
    },
    checkYourEmailInboxAndYourSpam: {
      message: 'Check your email inbox and your spam.',
    },
    pleaseEnterAValidSubdomain: {
      message: 'Revise su bandeja de entrada de correo electrónico y su correo no deseado.',
    },
    checkAllFields: {
      message: 'Revisa todos los campos.',
    },
    existingEmailTryAgain: {
      message: 'Correo electrónico existente. Intentar otra vez.',
    },
    invalidEmailTryAgain: {
      message: 'Email inválido. Intentar otra vez.',
    },
    invalidLastNameTryAgain: {
      message: 'Apellido inválido. Intentar otra vez.',
    },
    invalidNumberTryAgain: {
      message: 'Número invalido. Intentar otra vez.',
    },
    subdomainAlreadyExistsTryAgain: {
      message: 'El subdominio ya existe. Intentar otra vez.',
    },
    invalidSubdomainTryAgain: {
      message: 'Subdominio no válido. Intentar otra vez.',
    },
    invalidCNPJTryAgain: {
      message: 'CNPJ no válido. Intentar otra vez.',
    },
    CNPJAlreadyRegisteredTryAgain: {
      message: 'CNPJ ya registrado. Intentar otra vez.',
    },
    somethingUnexpectedHappenedTryAgainLater: {
      message: 'Sucedió algo inesperado. Vuelva a intentarlo más tarde.',
    },
    freeTtrialFor30days: {
      message: '¡Prueba gratuita durante 30 días!',
    },
    makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow: {
      message: 'Haz tu registro y experimenta las instalaciones de My Trip Menu ahora mismo',
    },
    makeYourRegistration: {
      message: 'Haz tu registro',
    },
    aboutYou: {
      message: 'Acerca de ti',
    },
    mandatoryFilling: {
      message: 'llenado obligatorio',
    },
    name: {
      message: 'Nombre*',
    },
    lastName: {
      message: 'Apellido*',
    },
    enterYourLastName: {
      message: 'Ingresa tu apellido.',
    },
    firstName: {
      message: 'Nombre de pila*',
    },
    enterYourName: {
      message: 'Escribe tu nombre.',
    },
    secondName: {
      message: 'Segundo nombre*',
    },
    enterYourSecondName: {
      message: 'Ingrese su segundo nombre.',
    },
    contactEmail: {
      message: 'Email de contacto*',
    },
    enterYourEmail: {
      message: 'Introduce tu correo electrónico.',
    },
    mobileWhatsapp: {
      message: 'Móvil/Whatsapp',
    },
    enterYourContactNumber: {
      message: 'Ingrese su número de contacto.',
    },
    pleaseEnterYourValidNumber: {
      message: 'Ingrese su número válido.',
    },
    aboutTheEstablishments: {
      message: 'Sobre los establecimientos',
    },
    nameOfTheEstablishment: {
      message: 'Nombre del establecimiento*',
    },
    enterTheNameOfYourEstablishment: {
      message: 'Introduzca el nombre de su establecimiento.',
    },
    enterYourCNPJ: {
      message: 'Ingresa tu CNPJ.',
    },
    enterAValidCNPJ: {
      message: 'Introduzca un CNPJ válido.',
    },
    chooseAURLForYourMenu: {
      message: 'Elija una URL para su menú*',
    },
    emailForLogosInTheManager: {
      message: 'Correo electrónico para logotipos en el administrador*',
    },
    enterAValidEmail: {
      message: 'Introduzca un correo electrónico válido',
    },
    chooseASubdomainForYourMenu: {
      message: 'Elige un subdominio para tu menú*',
    },
    about: {
      message: 'About',
    },
    solution: {
      message: 'Solución',
    },
    functionality: {
      message: 'Funcionalidad',
    },
    whoUse: {
      message: '¿Quién usa?',
    },
    prices: {
      message: 'Precios',
    },
    login: {
      message: 'Acceso',
    },
    register: {
      message: 'Registro',
    },
    yourCustomersAccessALinkWithYourDigitalMenuChooseTheProductsAndYouReceiveTheOrderDirectlyOnWhatsApp: {
      message: 'Tus clientes acceden a un enlace con tu menú digital, eligen los productos y tú recibes el pedido directamente en WhatsApp.',
    },
    digitalMenuModule: {
      message: 'Módulo de menú digital',
    },
    testFor: {
      message: 'Hacer una prueba por',
    },
    afterThisPeriodPayOnlyR99PerMonth: {
      message: 'después de este período, pague solo R$ 99 por mes',
    },
    enjoy: {
      message: 'Disfrutar!',
    },
    month: {
      message: 'mes',
    },
    annualPlan: {
      message: 'plan Anual',
    },
    aDigitalSolutionForYourCompany: {
      message: 'Una solución digital para tu empresa',
    },
    getAccessToASeriesOfFeaturesThatWillMakeManagementEasierOfYourBusiness: {
      message: 'Get access to a series of features that will make managing your business easier.',
    },
    receiveYourOrdersByWhatsApp: {
      message: 'Accede a una serie de funcionalidades que te facilitarán la gestión de tu negocio.',
    },
    accessTheDashboard: {
      message: 'acceder al tablero',
    },
    registerYourProducts: {
      message: 'Registra tus productos',
    },
    publishToYourCustomers: {
      message: 'Publica para tus clientes',
    },
    whoUsesMyTripMenu: {
      message: '¿Quién usa el menú Mi viaje?',
    },
    anIntelligentSolutionForDifferentSegments: {
      message: 'Una solución inteligente para diferentes segmentos.',
    },
    restaurants: {
      message: 'Restaurantes',
    },
    coffeeShops: {
      message: 'Cafeterías',
    },
    fastFoods: {
      message: 'Comidas rápidas',
    },
    bars: {
      message: 'Barras',
    },
    pubs: {
      message: 'Pubs',
    },
    foodTrucks: {
      message: 'Camiones de comida',
    },
    bakeries: {
      message: 'Panaderías',
    },
    pizzerias: {
      message: 'Pizzerías',
    },
    companyName: {
      message: 'Nombre de empresa*',
    },
    enterYourCompanyName: {
      message: 'Introduzca el nombre de su empresa.',
    },
    yourCustomersWontNeedToDownloadYetAnotherAppJustScanTheQRCodeThroughTheSmartphoneCameraAndThatsIt: {
      message: 'Sus clientes no necesitarán descargar otra aplicación más. Simplemente escanee el código QR, a través de la cámara del teléfono inteligente, ¡y listo!',
    },
    digitizeYourMenu: {
      message: 'Digitaliza tu menú!',
    },
    moreThan30LanguagesAvailable: {
      message: 'Más de 30 idiomas disponibles',
    },
    quote: {
      message: 'Oxigenar la carta con nuevos productos siempre genera altos costes de impresión por cambiar de carta. Con Menú Mi Viaje sumamos una nueva bebida y vendimos más de 100 unidades en los primeros días.',
    },
    quote2: {
      message: 'Eduardo - Pub Haka',
    },
    multilingual: {
      message: "plurilingüe"
    },
    yourMenuReadyForTheWorld: {
      message: "Tu menú listo para el mundo"
    },
    upToTenLanguagesAvailableForYourCustomer: {
      message: "Hasta 11 idiomas disponibles para tu cliente"
    },
    portugueseDotJapaneseDotSpanishDotEnglishDotGermanDotItalianDotChineseDotKoreanDotRussianDotArabic: {
      message: "Portugués • Japonés • Español • Inglés • Alemán • Italiano • Chino • Coreano • Ruso • Árabe • Francés"
    },
    oneClickTranslation: {
      message: "Traducción con un clic"
    },
    upToTenLanguagesAvailable: {
      message: "Hasta 11 idiomas disponibles"
    }
  },
};
