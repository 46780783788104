import React from "react";
import { Container, AboutContainer, Button } from "./styles";

import Element01 from "../../assets/element_bg_web_wpp01.svg";
import Element02 from "../../assets/blue_vector_1.png";
import Map from "../../assets/mapa_web.png";
import Iphone from "../../assets/image1.png";

import TranslatorFunction from "../I18n/Translator";
import { Link } from "react-scroll/modules";

function MenuLanguageWeb() {
  const yourMenuReadyForTheWorld = TranslatorFunction({
    path: "yourMenuReadyForTheWorld.message",
  });
  const upToTenLanguagesAvailableForYourCustomer = TranslatorFunction({
    path: "upToTenLanguagesAvailableForYourCustomer.message",
  });
  const portugueseDotJapaneseDotSpanishDotEnglishDotGermanDotItalianDotChineseDotKoreanDotRussianDotArabic =
    TranslatorFunction({
      path: "portugueseDotJapaneseDotSpanishDotEnglishDotGermanDotItalianDotChineseDotKoreanDotRussianDotArabic.message",
    });
  const oneClickTranslation = TranslatorFunction({
    path: "oneClickTranslation.message",
  });
  const freeTrial = TranslatorFunction({ path: "freeTrial.message" });
  const days = TranslatorFunction({ path: "days.message" });

  return (
    <Container>
      <div className="images">
        <img src={Element01} alt="" className="left" />
        <img src={Element02} alt="" className="right" />

        <div className="map">
          <img src={Map} alt="" />
        </div>
      </div>
      <AboutContainer>
        <div className="image">
          <img src={Iphone} alt="" />
        </div>
        <div className="texts">
          <h1>{yourMenuReadyForTheWorld}</h1>
          <h2>{upToTenLanguagesAvailableForYourCustomer}</h2>

          <h3>
            {
              portugueseDotJapaneseDotSpanishDotEnglishDotGermanDotItalianDotChineseDotKoreanDotRussianDotArabic
            }
          </h3>

          <div className="onClickTranslateBox">{oneClickTranslation}</div>

          <Button>
            <Link to="register" smooth={true} className="start">
              <h2>{freeTrial}</h2>
              <p>30 {days}</p>
            </Link>
          </Button>
        </div>
      </AboutContainer>
    </Container>
  );
}

export default MenuLanguageWeb;
