import styled from "styled-components";

export const Container = styled.button`
  cursor: pointer;
  width: 201px;
  height: 61px;
  background: #ef7522;
  border: 1px solid #ef7522;
  box-sizing: border-box;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  line-height: 18px;
  font-size: 15px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: 26px;
  @media (max-width: 376px) {
    width: 156px;
    height: 50px;
  }
`;
