/* eslint-disable import/no-anonymous-default-export */
export default {
  translations: {
    language: {
      message: "ru",
    },
    theSmartestSolutionToPresentYourMenu: {
      message: "Самое умное решение для представления вашего меню",
    },
    digitalMenu: {
      message: "Цифровое меню",
    },
    ordersBy: {
      message: "заказы по",
    },
    myTripMenuisADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphoneSimpleEasyAndFast: {
      message: "My Trip Menu — это цифровое меню с фотографиями, доступ к которому осуществляется с помощью QR-кода непосредственно со смартфона клиента. Просто, легко и быстро.",
    },
    knowMore: {
      message: "Узнать больше",
    },
    thePerfectSolutionForYourSecurityProtocols: {
      message: "Идеальное решение для ваших протоколов безопасности",
    },
    withMyTripYouCreateASafeEnvironmentAndAvoidTransmissionOfTheNewCoronavirus: {
      message: "С My Trip вы создаете безопасную среду и предотвращаете передачу нового коронавируса.",
    },
    noAppNeeded: {
      message: "Вам не нужно приложение"
    },
    directAccessByQRCode: {
      message: "Прямой доступ по QR-коду",
    },
    yourCustomersWontNeedToDownloadOneMoreapplicationJustScanTheQRCodeThroughTheCameraSmartphoneAndThatsIt: {
      message: "Вашим клиентам не нужно будет загружать еще одно приложение. Просто отсканируйте QR-код через камеру смартфона и все!",
    },
    start: {
      message: "Начать",
    },
    sellMore: {
      message: "Продавай больше",
    },
    and: {
      message: "а также",
    },
    save: {
      message: "спасти",
    },
    withPrintingCosts: {
      message: "с расходами на печать.",
    },
    freeTrial: {
      message: "Бесплатная пробная версия",
    },
    days: {
      message: "дни",
    },
    theMostSolution: {
      message: "Лучшее решение",
    },
    smartToPresent: {
      message: "умно представить",
    },
    yourMenu: {
      message: "ваше меню",
    },
    myTripMenuIsADigitalMenuWithPhotos: {
      message: "My Trip Menu — это цифровое меню с фотографиями.",
    },
    accessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: "доступ с помощью QR-кода непосредственно со смартфона клиента",
    },
    myTripMenuIsADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: "My Trip Menu — это цифровое меню с фотографиями, доступ к которому осуществляется с помощью QR-кода непосредственно со смартфона клиента.",
    },
    simpleEasyAndFast: {
      message: "Просто, легко и быстро",
    },
    simple: {
      message: "Простой",
    },
    easy: {
      message: "легкий",
    },
    fast: {
      message: "быстро",
    },
    digital: {
      message: "Цифровой",
    },
    receiveYourOrders: {
      message: "получать ваши заказы",
    },
    by: {
      message: "за",
    },
    automateTheService: {
      message: "Автоматизировать сервис",
    },
    ofYourDelivery: {
      message: "вашей доставки",
    },
    automateYourDeliveryService: {
      message: "Автоматизируйте свою службу доставки",
    },

    yourCustomersAccessA: {
      message: "Ваши клиенты получают доступ к",
    },
    linkWithYourDigitalMenu: {
      message: "связь с вашим цифровым меню,",
    },
    chooseTheProductsAndYou: {
      message: "выберите продукты и вы",
    },
    receiveTheOrderCirectlyOn: {
      message: "получить заказ прямо на",
    },
    speed: {
      message: "скорость",
    },
    agility: {
      message: "Ловкость",
    },
    orders: {
      message: "Запрос",
    },
    profits: {
      message: "прибыль",
    },
    testServiceLink: {
      message: "Ссылка на тестовый сервис",
    },
    sellMoreAndSaveOnPrintingCosts: {
      message: "Продавайте больше и экономьте на расходах на печать.",
    },
    aDigitalSolution: {
      message: "цифровое решение",
    },
    forYourCompany: {
      message: "для вашей компании",
    },

    getAccessToARangeOfFeatures: {
      message: "Получите доступ к целому ряду функций",
    },
    thatWillFacilitateTheManagementOfYourBusiness: {
      message: "что облегчит управление вашим бизнесом.",
    },
    fullMenuWithPhotos: {
      message: "Полное меню с фотографиями",
    },
    activateAndDeactivateItemsWithEase: {
      message: "Включайте и отключайте элементы с легкостью",
    },
    realTimePriceUpdate: {
      message: "Обновление цен в режиме реального времени",
    },
    testNewProductsQuickly: {
      message: "Быстро тестируйте новые продукты",
    },
    selectionFiltersByProductType: {
      message: "Фильтры выбора по типу продукта",
    },
    yourMenuIsReadyInMinutes: {
      message: "Ваше меню готово за считанные минуты!",
    },
    loginNow: {
      message: "войти сейчас",
    },
    registrationSuccessful: {
      message: "Регистрация прошла успешно!",
    },
    checkYourEmailInboxAndYourSpam: {
      message: "Проверьте свой почтовый ящик и спам.",
    },
    pleaseEnterAValidSubdomain: {
      message: "Пожалуйста, введите действительный поддомен.",
    },
    checkAllFields: {
      message: "Проверить все поля.",
    },
    existingEmailTryAgain: {
      message: "Существующая электронная почта. Попробуйте еще раз.",
    },
    invalidEmailTryAgain: {
      message: "Неверный адрес электронной почты. Попробуйте еще раз.",
    },
    invalidLastNameTryAgain: {
      message: "Неверная фамилия. Попробуйте еще раз",
    },
    invalidNumberTryAgain: {
      message: "Неверный номер. Повторите попытку.",
    },
    subdomainAlreadyExistsTryAgain: {
      message: "Поддомен уже существует. Попробуйте еще раз.",
    },
    invalidSubdomainTryAgain: {
      message: "Недействительный поддомен. Попробуйте еще раз.",
    },
    invalidCNPJTryAgain: {
      message: "Недействительный CNPJ. Попробуйте еще раз.",
    },
    CNPJAlreadyRegisteredTryAgain: {
      message: "CNPJ уже зарегистрирован. Попробуйте еще раз.",
    },
    somethingUnexpectedHappenedTryAgainLater: {
      message: "Произошло нечто неожиданное. Попробуйте позже.",
    },
    freeTtrialFor30days: {
      message: "Бесплатная пробная версия на 30 дней!",
    },
    makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow: {
      message: "Зарегистрируйтесь и испытайте возможности My Trip Menu прямо сейчас",
    },
    makeYourRegistration: {
      message: "Пройди регистрацию",
    },
    aboutYou: {
      message: "О тебе",
    },
    mandatoryFilling: {
      message: "обязательное заполнение",
    },
    name: {
      message: "Имя*",
    },
    lastName: {
      message: "Фамилия*",
    },
    enterYourLastName: {
      message: "Введите свою фамилию.",
    },
    firstName: {
      message: "Имя*",
    },
    enterYourName: {
      message: "Введите ваше имя.",
    },
    secondName: {
      message: "Второе имя*",
    },
    enterYourSecondName: {
      message: "Введите свое второе имя.",
    },
    contactEmail: {
      message: "Почта для связи*",
    },
    enterYourEmail: {
      message: "Введите адрес электронной почты.",
    },
    mobileWhatsapp: {
      message: "Мобильный/WhatsApp",
    },
    enterYourContactNumber: {
      message: "Введите свой контактный номер.",
    },
    pleaseEnterYourValidNumber: {
      message: "Введите свой контактный номер.",
    },
    aboutTheEstablishments: {
      message: "О заведениях",
    },
    nameOfTheEstablishment: {
      message: "Название заведения*",
    },
    enterTheNameOfYourEstablishment: {
      message: "Введите название вашего заведения.",
    },
    enterYourCNPJ: {
      message: "Введите свой CNPJ.",
    },
    enterAValidCNPJ: {
      message: "Введите действительный CNPJ.",
    },
    chooseAURLForYourMenu: {
      message: "Выберите URL для вашего меню*",
    },
    emailForLogosInTheManager: {
      message: "Электронная почта для логотипов в менеджере*",
    },
    enterAValidEmail: {
      message: "Введите действительный адрес электронной почты",
    },
    chooseASubdomainForYourMenu: {
      message: "Выберите субдомен для вашего меню*",
    },
    about: {
      message: "О",
    },
    solution: {
      message: "Решение",
    },
    functionality: {
      message: "Функциональность",
    },
    whoUse: {
      message: "Кто использует?",
    },
    prices: {
      message: "Цены",
    },
    login: {
      message: "Авторизоваться",
    },
    register: {
      message: "регистр",
    },
    yourCustomersAccessALinkWithYourDigitalMenuChooseTheProductsAndYouReceiveTheOrderDirectlyOnWhatsApp: {
      message: "Ваши клиенты получают доступ к ссылке с вашим цифровым меню, выбирают продукты, и вы получаете заказ прямо в WhatsApp",
    },
    digitalMenuModule: {
      message: "Модуль цифрового меню",
    },
    testFor: {
      message: "проверить по",
    },
    afterThisPeriodPayOnlyR99PerMonth: {
      message: "по истечении этого периода платите только 99 бразильских реалов в месяц.",
    },
    enjoy: {
      message: "Наслаждаться!",
    },
    month: {
      message: "месяц",
    },
    annualPlan: {
      message: "годовой план",
    },
    aDigitalSolutionForYourCompany: {
      message: "Цифровое решение для вашей компании",
    },
    getAccessToASeriesOfFeaturesThatWillMakeManagementEasierOfYourBusiness: {
      message: "Получите доступ к ряду функций, которые упростят управление вашим бизнесом.",
    },
    receiveYourOrdersByWhatsApp: {
      message: "Получайте заказы по WhatsApp",
    },
    accessTheDashboard: {
      message: "Доступ к панели управления",
    },
    registerYourProducts: {
      message: "Зарегистрируйте свои продукты",
    },
    publishToYourCustomers: {
      message: "Публикуйте для своих клиентов",
    },
    whoUsesMyTripMenu: {
      message: "Кто использует меню «Мое путешествие»?",
    },
    anIntelligentSolutionForDifferentSegments: {
      message: "Интеллектуальное решение для разных сегментов.",
    },
    restaurants: {
      message: "рестораны",
    },
    coffeeShops: {
      message: "кофейни",
    },
    fastFoods: {
      message: "фастфуд",
    },
    bars: {
      message: "бары",
    },
    pubs: {
      message: "бары",
    },
    foodTrucks: {
      message: "Food Trucks",
    },
    bakeries: {
      message: "бары",
    },
    pizzerias: {
      message: "бары",
    },
    companyName: {
      message: "Социальная причина*",
    },
    enterYourCompanyName: {
      message: "Введите название вашей компании.",
    },
    yourCustomersWontNeedToDownloadYetAnotherAppJustScanTheQRCodeThroughTheSmartphoneCameraAndThatsIt: {
      message: "Вашим клиентам не нужно будет загружать еще одно приложение. Просто отсканируйте QR-код через камеру смартфона и все!",
    },
    digitizeYourMenu: {
      message: "Оцифруйте свое меню!",
    },
    moreThan30LanguagesAvailable: {
      message: "Доступно более 30 языков",
    },
    yourMenuReadyForTheWorld: {
      message: "Ваше меню готово для всего мира",
    },
    quote: {
      message: 'Насыщение меню новыми продуктами всегда влечет за собой высокие затраты на печать при изменении меню. В My Trip Menu мы добавили новый напиток и за первые несколько дней продали более 100 единиц.',
    },
    quote2: {
      message: 'Эдуардо - Паб Хака',
    },
    multilingual: {
      message: "многоязычный"
    },
    upToTenLanguagesAvailableForYourCustomer: {
      message: "Вашему клиенту доступно до 11 языков"
    },
    portugueseDotJapaneseDotSpanishDotEnglishDotGermanDotItalianDotChineseDotKoreanDotRussianDotArabic: {
      message: "Португальский • Японский • Испанский • Английский • Немецкий • Итальянский • Китайский • Корейский • Русский • Арабский • Фрэнсис"
    },
    oneClickTranslation: {
      message: "Перевод в один клик"
    },
    upToTenLanguagesAvailable: {
      message: "Доступно до 11 языков"
    }
  },
};
