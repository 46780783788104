import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;

export const ContainerDescription = styled.div`
  margin: 0px auto 36px;

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;

  h1 {
    color: #606060;
    text-align: center;
    font-size: 24px;
  }
  h2 {
    text-align: center;
    margin: 24px 0;
    font-size: 15px;
    font-weight: 300;
    line-height: 32px;
  }
  button {
    font-size: 18px;
    font-weight: 600;
    height: 46px;
    width: 140px;
  }
`;

export const ContainerImages = styled.div`
  .mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    .left {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -10;
    }
    .right {
      position: absolute;
      right: 0;
      z-index: -10;
    }
    margin-bottom: 90px;
    .iphonepic {
      width: 60%;
    }
  }
  position: relative;
  .noapp-image-left {
    position: absolute;
    left: -46%;
    top: -30%;
    z-index: -100;
  }
  .noapp-image-right {
    position: absolute;
    right: -15%;
    top: -80%;
    z-index: -100;
  }
  .title {
    color: #606060;
    text-align: center;
    font-size: 16px;
    width: 70%;
    margin: 16px auto;
    font-weight: 400;
  }
`;

export const ContainerCovid = styled.div`
  position: relative;
`;

export const TitleCovid = styled.h3`
  font-family: "Biko", sans-serif;
  color: white;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 5px;
  @media (min-width: 562px) {
    font-size: 26px;
  }
  @media (min-width: 1000px) {
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 48px;
    letter-spacing: 0.04em;
  }
`;

export const Title1 = styled.h6`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #ffffff;

  @media (min-width: 562px) {
    font-size: 12px;
  }
  @media (min-width: 1000px) {
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
  }
`;

export const Title2 = styled.h6`
  left: 50%;
  top: 74px;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  margin-top: 22px;

  @media (min-width: 560px) {
    font-size: 14px;
  }
  @media (min-width: 1000px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
  }
`;

export const ContainerText = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  margin: 0 auto;
`;

export const BackgroundCovid = styled.img`
  width: 95%;
  margin: 28px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 182px;
  max-width: 1170px;
`;

export const App = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 35px;
  text-align: center;
  color: #5d5d5d;
  margin-bottom: 18px;
`;

export const LitleDivisor = styled.div`
  width: 34px;
  height: 2px;
  margin-bottom: 18px;
  background: #ff8815;
  margin: 0 auto;
`;

export const ContainerGrey = styled.div`
  width: 100%;
  background-color: #efefef;
  position: relative;
  .solution {
    .left {
      position: absolute;
      left: 0;
    }
    .right {
      position: absolute;
      right: 0;
    }
    .titles {
      padding: 36px 0;

      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      h1,
      h2 {
        text-align: center;
        color: #5d5d5d;
        margin: 16px 0;
      }
      h2 {
        color: #7b7b7b;
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
`;

export const IconDescription = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  img {
    width: 24px;
    height: 32px;
  }
  div {
    margin-left: 20px;
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 16px;
      color: #959595;
    }
    .em-breve {
      font-family: Source Sans Pro;
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #959595;
    }
  }
`;

export const DivIcons = styled.div`
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  .iphoneIcon {
    width: 50%;
  }
`;

export const Icones = styled.div`
  margin-top: 24px;
`;

export const ContainerWhatsapp = styled.div`
  position: relative;
  .img-wpp {
    position: absolute;
    z-index: -10;
    left: -30%;
    width: 50%;
  }
  .titles-wpp {
    h1 {
      color: #f37e0c;
      font-size: 28px;
      text-align: center;
      margin-bottom: 12px;
      line-height: 42px;
    }

    h3 {
      line-height: 28px;
      margin-top: 12px;
      font-size: 18px;
      color: #606060;
      text-align: center;
      font-weight: 600;
    }
  }

  .texts-wpp {
    width: 90%;
    margin: 36px auto;
    display: flex;
    justify-content: space-around;
    .texts-wpp-div {
      margin-top: 40px;
      width: 60%;
      h1 {
        color: #606060;
        font-weight: 400;
        font-size: 15px;
        margin-bottom: 38px;
      }
      .border-text {
        color: #ff952e;
        padding: 12px;
        border: 2px solid;
        border-radius: 16px;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 38px;
      }
      button {
        width: 196px;
      }
    }
    .texts-img-div {
      img {
        width: 100%;
        max-width: 250px !important;
      }
    }
  }
`;
