import React, { Component } from "react";
import { Translator } from '../../../components/I18n';
import { Hakapub, Subtexto, Autor } from "./style";
class index extends Component {
  render() {
    return (
      <Hakapub>
        <Subtexto>
          "<Translator path="quote.message" />"
        </Subtexto>
        <Autor>Eduardo - Haka Pub</Autor>
      </Hakapub>
    );
  }
}

export default index;
