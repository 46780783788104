import React from "react";

import {
  Container,
  Texts,
  Bold,
  Valores,
  Real1,
  RealMes,
  Real2,
  RealMes2,
  PastMes,
  Mes,
  Plano,
  ImageContainer,
} from "./styles";
import elemento06 from "../../assets/elemento06_web.png";
import elemento10 from "../../assets/elemento10_web.png";
import elemento17 from "../../assets/elemento17_web.png";
import iphone4 from "../../assets/iphone4_web.png";
import TranslatorFunction from '../I18n/Translator';

const Section7 = ({ id }) => {

  const digitalMenuModule = TranslatorFunction({ path: 'digitalMenuModule.message' });
  const testFor = TranslatorFunction({ path: 'testFor.message' });
  const days = TranslatorFunction({ path: 'days.message' });
  const afterThisPeriodPayOnlyR99PerMonth = TranslatorFunction({ path: 'afterThisPeriodPayOnlyR99PerMonth.message' });
  const enjoy = TranslatorFunction({ path: 'enjoy.message' });
  const month = TranslatorFunction({ path: 'month.message' });
  const annualPlan = TranslatorFunction({ path: 'annualPlan.message' });

  return (
    <Container id={id}>
      <img src={elemento06} className="el01" alt="" />
      <img src={elemento10} className="el02" alt="" />
      <img src={elemento17} className="el03" alt="" />

      <Texts>
        <h1>{digitalMenuModule}</h1>
        <div className="tab" />
        <h2>
          {testFor} <Bold>30 {days}</Bold>, {afterThisPeriodPayOnlyR99PerMonth} <Bold>{enjoy}</Bold>
        </h2>
        <Valores>
          <div className="old-price">
            <Real1>R$</Real1>
            <RealMes>159</RealMes>
            <PastMes>/{month}</PastMes>
          </div>
          <div className="new-price">
            <Real2>R$</Real2>
            <RealMes2>99</RealMes2>
            <Mes>/{month}</Mes>
          </div>
          <Plano>{annualPlan}</Plano>
        </Valores>
      </Texts>
      <ImageContainer>
        <img src={iphone4} alt="" />
      </ImageContainer>
    </Container>
  );
};

export default Section7;
