/* eslint-disable import/no-anonymous-default-export */
export default {
  translations: {
    language: {
      message: "ar",
    },
    theSmartestSolutionToPresentYourMenu: {
      message:"أذكى حل لتقديم قائمتك",
    },
    digitalMenu: {
      message: "القائمة الرقمية ",
    },
    ordersBy: {
      message: "الطلبات من خلال ",
    },
    myTripMenuisADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphoneSimpleEasyAndFast: {
      message: "قائمة My Trip هي قائمة رقمية تحتوي على صور ، يمكن الوصول إليها عن طريق QR Code مباشرة من الهاتف الذكي للعميل. بسيط وسهل وسريع.",
    } ,
    knowMore: {
      message: "تعرف أكثر",
    } ,
    thePerfectSolutionForYourSecurityProtocols: {
      message: "الحل الأمثل لبروتوكولات الأمان الخاصة بك ",
    } ,
    withMyTripYouCreateASafeEnvironmentAndAvoidTransmissionOfTheNewCoronavirus: {
      message: "مع My Trip , تخلق بيئة آمنة وتمنع انتقال فيروس كورونا الجديد. ",
    } ,
    noAppNeeded: {
      message: "لا حاجة إلى تطبيق" ,
    } ,
    directAccessByQRCode: {
      message: "الوصول المباشر عن طريق رمز الاستجابة السريعة" ,
    } ,
    yourCustomersWontNeedToDownloadOneMoreapplicationJustScanTheQRCodeThroughTheCameraSmartphoneAndThatsIt: {
      message: "لن يحتاج عملاؤك إلى تنزيل تطبيق آخر. ما عليك سوى مسح رمز الاستجابة السريعة ضوئيًا , من خلال كاميرا الهاتف الذكي , وهذا كل شيء!" ,
    } ,
    start: {
      message:"للبدأ",
    },
    sellMore: {
      message: "بيع أكثر",
    },
    and: {
      message: "و",
    },
    save: {
      message: "حفظ",
    },
    withPrintingCosts: {
      message: "مع تكاليف الطباعة.",
    },
    freeTrial: {
      message: "تجربة مجانية",
    },
    days: {
      message: "أيام",
    },
    theMostSolution: {
      message: "الأكثر حل",
    },
    smartToPresent: {
      message: "ذكي لتقديمه",
    },
    yourMenu: {
      message: "قائمتك",
    },
    myTripMenuIsADigitalMenuWithPhotos: {
      message: "قائمة رحلتي هي قائمة رقمية تحتوي على صور",
    },
    accessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: "يمكن الوصول إليها عن طريق QR Code مباشرة من الهاتف الذكي للعميل",
    },
    myTripMenuIsADigitalMenuWithPhotosAccessedByQRCodeDirectlyFromTheCustomersSmartphone: {
      message: "قائمة My Trip هي قائمة رقمية تحتوي على صور , يمكن الوصول إليها عن طريق رمز الاستجابة السريعة مباشرة من الهاتف الذكي للعميل. ",
    } ,
    simpleEasyAndFast: {
      message:"بسيط وسهل وسريع" ,
    },
    simple: {
      message: "بسيط",
    },
    easy: {
      message: "سهل",
    },
    fast: {
      message: "سريع",
    },
    digital: {
      message: "رقمي",
    },
    receiveYourOrders: {
      message: "تلقي طلباتك",
    },
    by: {
      message: "لكل",
    },
    automateTheService: {
      message: "أتمتة الخدمة ",
    },
    ofYourDelivery: {
      message: "من التسليم الخاص بك",
    },
    automateYourDeliveryService: {
      message: "أتمتة خدمة التوصيل الخاصة بك",
    },

    yourCustomersAccessA: {
      message: "يصل عملاؤك إلى أ",
    },
    linkWithYourDigitalMenu: {
      message: "الارتباط بقائمتك الرقمية ,",
    },
    chooseTheProductsAndYou: {
      message: "اختر المنتجات وأنت",
    },
    receiveTheOrderCirectlyOn: {
      message: "استلام الطلب مباشرة على",
    },
    speed: {
      message: "سرعة",
    },
    agility: {
      message: "رشاقة",
    },
    orders: {
      message: "طلب",
    },
    profits: {
      message: "أرباح",
    },
    testServiceLink: {
      message: "اختبار رابط الخدمة",
    },
    sellMoreAndSaveOnPrintingCosts: {
      message: "قم ببيع المزيد ووفر تكاليف الطباعة.",
    },
    aDigitalSolution: {
      message: "حل رقمي",
    },
    forYourCompany: {
      message: "لشركتك",
    },

    getAccessToARangeOfFeatures: {
      message: "الحصول على مجموعة من الميزات",
    },
    thatWillFacilitateTheManagementOfYourBusiness: {
      message: "من شأنها تسهيل إدارة عملك.",
    },
    fullMenuWithPhotos: {
      message: "قائمة كاملة بالصور",
    },
    activateAndDeactivateItemsWithEase: {
      message: "تنشيط وإلغاء تنشيط العناصر بكل سهولة",
    },
    realTimePriceUpdate: {
      message: "تحديث السعر في الوقت الحقيقي",
    },
    testNewProductsQuickly: {
      message: "اختبر المنتجات الجديدة بسرعة",
    },
    selectionFiltersByProductType: {
      message: "مرشحات التحديد حسب نوع المنتج",
    },
    yourMenuIsReadyInMinutes: {
      message: "قائمتك جاهزة في دقائق!",
    },

    loginNow: {
      message: "تسجيل الدخول الآن",
    },
    registrationSuccessful: {
      message: "تم التسجيل بنجاح!",
    },
    checkYourEmailInboxAndYourSpam: {
      message: "تحقق من صندوق البريد الإلكتروني الخاص بك والبريد العشوائي الخاص بك.",
    },
    pleaseEnterAValidSubdomain: {
      message: "الرجاء إدخال مجال فرعي صالح.",
    },
    checkAllFields: {
      message: "تحقق من جميع الحقول.",
    },
    existingEmailTryAgain: {
      message: "بريد إلكتروني موجود. يرجى المحاولة مرة أخرى.",
    },
    invalidEmailTryAgain: {
      message: "بريد إلكتروني خاطئ. حاول مرة أخرى.",
    },
    invalidLastNameTryAgain: {
      message: "اسم العائلة غير صالح. حاول مرة أخرىe",
    },
    invalidNumberTryAgain: {
      message: "رقم غير صالح. حاول مرة أخرى",
    },
    subdomainAlreadyExistsTryAgain: {
      message: "المجال الفرعي موجود بالفعل. حاول مرة أخرى.",
    },
    invalidSubdomainTryAgain: {
      message: "المجال الفرعي غير صالح. حاول مرة أخرى.",
    },
    invalidCNPJTryAgain: {
      message: "CNPJ غير صالح. حاول مرة أخرى.",
    },

    CNPJAlreadyRegisteredTryAgain: {
      message: "CNPJ مسجلة بالفعل. حاول مرة أخرى.",
    },
    somethingUnexpectedHappenedTryAgainLater: {
      message: "حدث شيء غير متوقع. حاول مرة أخرى في وقت لاحق.",
    },
    freeTtrialFor30days: {
      message: "نسخة تجريبية مجانية لمدة 30 يومًا!",
    },
    makeYourRegistrationAndExperienceTheFacilitiesOfMyTripMenuRightNow: {
      message: "قم بالتسجيل وتجربة مرافق My Trip Menu في الوقت الحالي ",
    } ,
    makeYourRegistration: {
      message: "قم بالتسجيل",
    },
    aboutYou: {
      message: "حولك",
    },
    mandatoryFilling: {
      message: "ملء إلزامي",
    },
    name: {
      message: "اسم*",
    },
    lastName: {
      message: "اللقب*",
    },
    enterYourLastName: {
      message: "أدخل اسمك الأخير.",
    },
    firstName: {
      message: "الاسم الاول*",
    },
    enterYourName: {
      message: "أدخل أسمك.",
    },
    secondName: {
      message: "الاسم الثاني*",
    },
    enterYourSecondName: {
      message: "أدخل اسمك الثاني.",
    },
    contactEmail: {
      message: "تواصل بالبريد الاكتروني*",
    },
    enterYourEmail: {
      message: "أدخل بريدك الإلكتروني.",
    },
    mobileWhatsapp: {
      message: "موبايل / واتس اب",
    },
    enterYourContactNumber: {
      message: "أدخل رقم الاتصال الخاص بك.",
    },
    pleaseEnterYourValidNumber: {
      message: "الرجاء إدخال رقمك الصحيح.",
    },
    aboutTheEstablishments: {
      message: "عن المؤسسات",
    },
    nameOfTheEstablishment: {
      message: "اسم المنشأة *",
    },
    enterTheNameOfYourEstablishment: {
      message: "أدخل اسم مؤسستك.",
    },
    enterYourCNPJ: {
      message: "أدخل رقم CNPJ الخاص بك.",
    },
    enterAValidCNPJ: {
      message: "أدخل CNPJ صالحًا.",
    },
    chooseAURLForYourMenu: {
      message: "اختر عنوان URL لقائمتك *",
    },
    emailForLogosInTheManager: {
      message: "البريد الإلكتروني للشعارات في المدير *",
    },
    enterAValidEmail: {
      message: "أدخل بريد إلكتروني متاح",
    },
    chooseASubdomainForYourMenu: {
      message: "اختر نطاقًا فرعيًا لقائمتك *",
    },
    about: {
      message: "حول",
    },
    solution: {
      message: "المحلول",
    },
    functionality: {
      message: "وظائف",
    },
    whoUse: {
      message: "من يستخدم؟",
    },
    prices: {
      message: "الأسعار",
    },
    login: {
      message: "تسجيل الدخول",
    },
    register: {
      message: "تسجيل",
    },
    yourCustomersAccessALinkWithYourDigitalMenuChooseTheProductsAndYouReceiveTheOrderDirectlyOnWhatsApp: {
      message: "يصل عملاؤك إلى رابط بقائمتك الرقمية , واختيار المنتجات وستتلقى الطلب مباشرة على WhatsApp. ",
    } ,
    digitalMenuModule: {
      message: "وحدة القائمة الرقمية",
    },
    testFor: {
      message: "اختبار بواسطة ",
    },
    afterThisPeriodPayOnlyR99PerMonth: {
      message: "بعد هذه الفترة , ادفع فقط 99 ريالاً برازيليًا شهريًا ",
    },
    enjoy: {
      message: "يتمتع!",
    },
    month: {
      message: "شهر",
    },
    annualPlan: {
      message: "الخطة السنوية",
    },
    aDigitalSolutionForYourCompany: {
      message: "حل رقمي لشركتك",
    },
    getAccessToASeriesOfFeaturesThatWillMakeManagementEasierOfYourBusiness: {
      message: "احصل على وصول إلى سلسلة من الميزات التي ستجعل إدارة عملك أسهل. ",
    } ,
    receiveYourOrdersByWhatsApp: {
      message: "تلقي طلباتك عبر WhatsApp ",
    } ,
    accessTheDashboard: {
      message: "الوصول إلى لوحة القيادة ",
    } ,
    registerYourProducts: {
      message: "سجل منتجاتك",
    },
    publishToYourCustomers: {
      message: "انشر إلى عملائك",
    },
    whoUsesMyTripMenu: {
      message: "من يستخدم قائمة رحلتي؟",
    },
    anIntelligentSolutionForDifferentSegments: {
      message: "حل ذكي لشرائح مختلفة ",
    },
    restaurants: {
      message: "مطاعم",
    },
    coffeeShops: {
      message: "المقاهي",
    },
    fastFoods: {
      message: "الأطعمة السريعة",
    },
    bars: {
      message: "الحانات",
    },
    pubs: {
      message: "الحانات",
    },
    foodTrucks: {
      message: "شاحنات الغذاء",
    },
    bakeries: {
      message: "مخابز",
    },
    pizzerias: {
      message: "مطاعم بيتزا",
    },
    companyName: {
      message: "اسم الشركة *",
    },
    enterYourCompanyName: {
      message: "أدخل اسم شركتك.",
    },
    yourCustomersWontNeedToDownloadYetAnotherAppJustScanTheQRCodeThroughTheSmartphoneCameraAndThatsIt: {
      message: "لن يحتاج عملاؤك إلى تنزيل تطبيق آخر. ما عليك سوى مسح رمز الاستجابة السريعة ضوئيًا عبر كاميرا الهاتف الذكي , وهذا كل شيء! ",
    } ,
    digitizeYourMenu: {
      message: "رقمنة قائمتك! ",
    } ,
    moreThan30LanguagesAvailable: {
      message: "يتوفر أكثر من 30 لغة ",
    } ,
    quote: {
      message: 'يؤدي تأكسد القائمة بمنتجات جديدة دائمًا إلى تكاليف طباعة عالية لتغيير القوائم. من خلال My Trip Menu ، أضفنا مشروبًا جديدًا وباعنا أكثر من 100 وحدة في الأيام القليلة الأولى.',
    },
    quote2: {
      message: 'إدواردو - حانة هاكا',
    },
    multilingual: {
      message: "متعدد اللغات"
    },
    yourMenuReadyForTheWorld: {
      message: "قائمتك جاهزة للعالم"
    },
    upToTenLanguagesAvailableForYourCustomer: {
      message: "ما يصل إلى 11 لغات متاحة لعميلك"
    },
    portugueseDotJapaneseDotSpanishDotEnglishDotGermanDotItalianDotChineseDotKoreanDotRussianDotArabic: {
      message: "فرانسيس • اليابانية • الإسبانية • الإنجليزية • الألمانية • الإيطالية • الصينية • الكورية • الروسية • العربية لبرتغالية "
    },
    oneClickTranslation: {
      message: "ترجمة بنقرة واحدة"
    },
    upToTenLanguagesAvailable: {
      message: "يتوفر ما يصل إلى  11 لغات"
    }
  },
};
