import React from "react";
import elemento06 from "../../assets/elemento06_web.png";
import elemento08 from "../../assets/elemento08_web.png";
import elemento07 from "../../assets/elemento07_web.png";
import notebook from "../../assets/notebook_web.png";
import restaurante from "../../assets/images6/restaurante.svg";
import pubs from "../../assets/images6/pubs.svg";
import fastfood from "../../assets/images6/fastfood.svg";
import cafeterias from "../../assets/images6/cafeterias.svg";
import foodtrucks from "../../assets/images6/foodtrucks.svg";
import bares from "../../assets/images6/bares.svg";
import padarias from "../../assets/images6/padarias.svg";
import pizzaria from "../../assets/images6/pizzaria.svg";

import {
  Container,
  Simple,
  Who,
  ContainerGrey,
  ContainerWhite,
} from "./styles";
import TranslatorFunction from "../I18n/Translator";

const Section5 = ({ id }) => {
  const simple = TranslatorFunction({ path: "simple.message" });
  const easy = TranslatorFunction({ path: "easy.message" });
  const digital = TranslatorFunction({ path: "digital.message" });
  const yourMenuIsReadyInMinutes = TranslatorFunction({
    path: "yourMenuIsReadyInMinutes.message",
  });
  const makeYourRegistration = TranslatorFunction({
    path: "makeYourRegistration.message",
  });
  const accessTheDashboard = TranslatorFunction({
    path: "accessTheDashboard.message",
  });
  const registerYourProducts = TranslatorFunction({
    path: "registerYourProducts.message",
  });
  const publishToYourCustomers = TranslatorFunction({
    path: "publishToYourCustomers.message",
  });
  const loginNow = TranslatorFunction({ path: "loginNow.message" });
  const whoUsesMyTripMenu = TranslatorFunction({
    path: "whoUsesMyTripMenu.message",
  });
  const anIntelligentSolutionForDifferentSegments = TranslatorFunction({
    path: "anIntelligentSolutionForDifferentSegments.message",
  });
  const restaurants = TranslatorFunction({ path: "restaurants.message" });
  const coffeeShops = TranslatorFunction({ path: "coffeeShops.message" });
  const fastFoods = TranslatorFunction({ path: "fastFoods.message" });
  const bars = TranslatorFunction({ path: "bars.message" });
  const pubsLabel = TranslatorFunction({ path: "pubs.message" });
  const foodTrucks = TranslatorFunction({ path: "foodTrucks.message" });
  const bakeries = TranslatorFunction({ path: "bakeries.message" });
  const pizzerias = TranslatorFunction({ path: "pizzerias.message" });

  return (
    <Container>
      <ContainerGrey>
        <img src={elemento06} className="element06" alt="" />
        <Simple>
          <div className="texts">
            <div className="titles">
              <h2>
                {simple}. {easy}.{" "}
              </h2>
              <h2 className="digital">{digital}.</h2>
            </div>
            <h3>{yourMenuIsReadyInMinutes}</h3>

            <div className="buttons">
              <div>
                <button>1</button>
                <h3>{makeYourRegistration}</h3>
              </div>
              <div>
                <button>2</button>
                <h3>{accessTheDashboard}</h3>
              </div>
              <div>
                <button>3</button>
                <h3>{registerYourProducts}</h3>
              </div>
              <div>
                <button>4</button>
                <h3>{publishToYourCustomers}</h3>
              </div>
            </div>
            <a
              href={`${process.env.REACT_APP_LOGIN}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="button">
                <button>{loginNow}</button>
              </div>
            </a>
          </div>
          <div className="images">
            <img src={notebook} alt="" />
          </div>
        </Simple>
      </ContainerGrey>
      <ContainerWhite id={id}>
        <img src={elemento08} className="el01" alt="" />
        <img src={elemento07} className="el02" alt="" />
        <Who>
          <div className="titles">
            <h1>{whoUsesMyTripMenu}</h1>
            <div className="tab" />
            <h2>{anIntelligentSolutionForDifferentSegments}</h2>
          </div>
          <div className="icons">
            <div>
              <img src={restaurante} alt="" />
              <h2>{restaurants}</h2>
            </div>
            <div>
              <img src={cafeterias} alt="" />
              <h2>{coffeeShops}</h2>
            </div>
            <div>
              <img src={fastfood} alt="" />
              <h2>{fastFoods}</h2>
            </div>
            <div>
              <img src={bares} alt="" />
              <h2>{bars}</h2>
            </div>
            <div>
              <img src={pubs} alt="" />
              <h2>{pubsLabel}</h2>
            </div>
            <div>
              <img src={foodtrucks} alt="" />
              <h2>{foodTrucks}</h2>
            </div>
            <div>
              <img src={padarias} alt="" />
              <h2>{bakeries}</h2>
            </div>
            <div>
              <img src={pizzaria} alt="" />
              <h2>{pizzerias}</h2>
            </div>
          </div>
        </Who>
      </ContainerWhite>
    </Container>
  );
};

export default Section5;
