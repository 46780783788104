import React from "react";

import { Container } from "./styles";

const Funcionalidade = () => {
  return (
    <Container>
      <h1>Funcionalidade</h1>
    </Container>
  );
};

export default Funcionalidade;
