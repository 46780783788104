import styled from "styled-components";

export const Container = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Elemento8 = styled.img`
  position: absolute;
  left: 0;
`;
export const Elemento10 = styled.img`
  position: absolute;
  right: 0;
`;

export const Valores = styled.div`
  margin-top: 30px;
  .old-price {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .new-price {
    position: relative;
    display: flex;
    justify-content: center;
  }
`;

export const Real1 = styled.h6`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #959595;
  margin-top: 5.5px;
`;

export const RealMes = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  color: #959595;
  text-decoration: line-through;
`;

export const Real2 = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  color: #7b7b7b;
  margin-top: 14px;
`;

export const RealMes2 = styled.h2`
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 59px !important;
  line-height: 78px !important;
  text-align: center !important;
  color: #0f82a8 !important;
`;

export const Mes = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 53px;
  text-align: center;
  color: #0f82a8;
  margin-top: 12px;
`;

export const Plano = styled.h6`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #959595;
`;

export const Titulo = styled.h1`
  text-align: center;
  background: #ffffff;
  color: #5d5d5d;
  margin-bottom: 18px;
`;
export const Espacamento = styled.img`
  width: 34px;
  height: 2px;
  background: #ff8815;
  margin-bottom: 18px;
`;
export const Negrito = styled.b`
  color: #ff8815;
`;
export const Subtitulo = styled.h4`
  margin-top: 16px;
  font-style: bold;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: #959595;
`;

export const ContainerValues = styled.div``;
